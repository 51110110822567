import { SxProps } from '@mui/system';

export const hideDatePickerStyles: SxProps = {
  "& .MuiIconButton-root": {
    display: "none",
  },
};

export const clearButtonStyles: SxProps = {
  fontWeight: 500,
  fontSize: 16,
};

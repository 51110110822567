import {
  Grow,
  MenuList,
  Paper,
  Popper,
  PopperProps,
  Typography,
} from '@mui/material';
import { IPatient } from 'src/types/Patient';
import {
  searchNotificationLabelStyles,
  searchResultStyles,
  SEARCH_CONTAINER_TRANSITION,
} from './styles';
import { PatientSearchBarResultItem } from './PatientSearchBarResultItem';

export type PatientSearchBarResultProps = PopperProps & {
  patients: IPatient[];
  error?: string;
  isEmpty?: boolean;
};

export function PatientSearchBarResult({
  patients,
  error,
  isEmpty,
  ...rest
}: PatientSearchBarResultProps): JSX.Element {
  return (
    <Popper
      style={{ width: '100%', paddingTop: 16 }}
      transition
      disablePortal
      placement="bottom-start"
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
          timeout={SEARCH_CONTAINER_TRANSITION}
        >
          <Paper sx={searchResultStyles}>
            {isEmpty && (
              <Typography sx={searchNotificationLabelStyles}>
                No patient found
              </Typography>
            )}
            <MenuList>
              {patients.map((patient) => (
                <PatientSearchBarResultItem
                  key={patient.id}
                  patient={patient}
                />
              ))}
            </MenuList>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

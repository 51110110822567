import { SxProps } from '@mui/system';

export const headingContainerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  paddingBottom: 4,
};

export const headingLeftStyles: SxProps = {
  flex: 1,
};

export const headingRightStyles: SxProps = {
  display: 'flex',
};

import axios, { AxiosInstance } from 'axios';
import { authService } from './AuthService';

const createAxiosService = () => {
  let publicInstance: AxiosInstance;
  let securedInstance: AxiosInstance;
  let authenticationToken: string;

  const baseURL = process.env.REACT_APP_API_ENDPOINT;

  const getPublicInstance = () => {
    if (!publicInstance) {
      publicInstance = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
        },
      });
    }

    return publicInstance;
  };

  const getSecuredInstance = () => {
    const { authenticationToken: currentAuthenticationToken } =
      authService.getAuthenticationData();

    if (!currentAuthenticationToken) {
      throw new Error('Authentication token not found');
    }

    if (
      currentAuthenticationToken !== authenticationToken ||
      !securedInstance
    ) {
      securedInstance = axios.create({
        baseURL,
        headers: {
          Authorization: `Bearer ${currentAuthenticationToken}`,
          Accept: 'application/json',
        },
      });

      authenticationToken = currentAuthenticationToken;
    }

    return securedInstance;
  };

  return Object.freeze({
    getPublicInstance,
    getSecuredInstance,
  });
};

export const axiosService = createAxiosService();

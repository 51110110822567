import { SxProps } from '@mui/system';

export const formContainerStyles: SxProps = {
  maxWidth: 500,
  margin: '0 auto',
};

export const headingStyles: SxProps = {
  textAlign: 'center',
  paddingBottom: 7,
};

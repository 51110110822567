import {
  IInjectionSetting,
  IInsulinDosage,
  IInsulinPumpSetting,
  INewInsulinDosageData,
} from 'src/types/InsulinDosage';
import { IInsulinDosageService } from 'src/types/InsulinDosageService';
import { axiosService } from './AxiosService';

const transformInsulinPumpSettingResponse = ({
  id,
  basal,
  ic,
  isf,
  time,
}: Record<string, string | number>): IInsulinPumpSetting => ({
  id: id as number,
  basal: basal as number,
  isf: isf as number,
  ic: ic as number,
  time: time as string,
});

const transformInjectionSettingsResponse = ({
  id,
  ic_ratio,
  correction_factor,
  basal_insulin,
  time_from,
  time_to,
}: Record<string, string | number>): IInjectionSetting => ({
  id: id as number,
  icRatio: ic_ratio as number,
  correctionFactor: correction_factor as number,
  basalInsulin: basal_insulin as number,
  timeFrom: time_from as string,
  timeTo: time_to as string,
});

const transformInsulinDosageResponse = ({
  id,
  created_at,
  insulin_pump_settings = [],
  injection_settings = [],
}: Record<string, string | number | unknown>): IInsulinDosage => ({
  id: id as number,
  createdAt: created_at as string,
  insulinPumpSettings: (
    insulin_pump_settings as Record<string, string | number>[]
  ).map(transformInsulinPumpSettingResponse),
  injectionSettings: (
    injection_settings as Record<string, string | number>[]
  ).map(transformInjectionSettingsResponse),
});

const transformInsulinDosageRequest = ({
  insulinPumpSettings,
  injectionSettings,
}: INewInsulinDosageData): {
  insulin_pump_settings_attributes: Record<string, string | number>[];
  injection_settings_attributes: Record<string, string | number>[];
} => ({
  insulin_pump_settings_attributes: insulinPumpSettings,
  injection_settings_attributes: injectionSettings.map(
    ({ timeFrom, timeTo, icRatio, basalInsulin, correctionFactor }) => ({
      time_from: timeFrom,
      time_to: timeTo,
      ic_ratio: icRatio,
      basal_insulin: basalInsulin,
      correction_factor: correctionFactor,
    })
  ),
});

const getInsulinDosage = async (patientId: number): Promise<IInsulinDosage> => {
  const { data } = (await axiosService
    .getSecuredInstance()
    .get(`/patients/${patientId}/insulin_dosages`)
    .then((response) => response.data)) as { data: unknown[] };

  if (!data || !data[0]) {
    throw new Error('Insulin dosage not found');
  }

  return transformInsulinDosageResponse(
    data[0] as Record<string, string | number | unknown>
  );
};

const create = async (
  patientId: number,
  insulinDosageData: INewInsulinDosageData
): Promise<IInsulinDosage> => {
  const { insulin_dosage } = (await axiosService
    .getSecuredInstance()
    .post(`/patients/${patientId}/insulin_dosages`, {
      insulin_dosage: transformInsulinDosageRequest(insulinDosageData),
    })
    .then((response) => response.data)) as {
    insulin_dosage: Record<string, string | number | unknown>;
  };

  return transformInsulinDosageResponse(insulin_dosage);
};

export const insulinDosageService: IInsulinDosageService = Object.freeze({
  getInsulinDosage,
  create,
});

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { AccountForm } from 'src/components/forms/AccountForm';
import { useAuth, useHandleUpdateUser } from 'src/hooks/AuthHook';
import { formContainerStyles, headingStyles } from './styles';

export const AccountPage = observer(function (): JSX.Element {
  const { user } = useAuth();
  const [handleUpdateUser, { isLoading }] = useHandleUpdateUser();

  return (
    <Box sx={formContainerStyles}>
      <Typography variant="h1" sx={headingStyles}>
        Account
      </Typography>
      <AccountForm
        defaultValues={user}
        onSubmit={handleUpdateUser}
        isLoading={isLoading}
      />
    </Box>
  );
});

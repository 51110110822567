import { useCallback, useState } from 'react';
import { AsyncState } from 'src/types/Hook';

export const useAsyncState = (): {
  state: AsyncState;
  start: () => void;
  finish: (params?: { error: string, errors?: Record<string, string> }) => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [started, setStarted] = useState(false);

  const start = useCallback(() => {
    setIsLoading(true);
    setIsSuccessful(false);
    setError('');
    setErrors({});
    setStarted(true);
  }, []);

  const finish = useCallback((args: { error: string, errors?: Record<string, string> } | undefined) => {
    setIsLoading(false);
    setIsSuccessful(!args?.error && !args?.errors);
    setError(args?.error || '');
    setErrors(args?.errors || {});
  }, []);

  return {
    state: {
      isLoading,
      isSuccessful,
      error,
      errors,
      started,
    },
    start,
    finish,
  };
};

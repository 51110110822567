import { Alert, Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores';
import { alertsContainerStyles, alertStyles } from './styles';

export const AlertsContainer = observer(function (): JSX.Element {
  const {
    alertStore: { alertsList, removeAlert },
  } = useStores();

  return (
    <>
      {alertsList.length > 0 && (
        <Box sx={alertsContainerStyles}>
          {alertsList.map((alert) => (
            <Alert
              onClose={() => {
                removeAlert(alert.id);
              }}
              severity={alert.type}
              key={alert.id}
              icon={false}
              sx={alertStyles}
            >
              <Typography>{alert.content}</Typography>
            </Alert>
          ))}
        </Box>
      )}
    </>
  );
});

import { action, makeObservable, observable, runInAction } from 'mobx';
import { IAuthService } from 'src/types/AuthService';
import { serviceLoader } from 'src/stores/ServiceLoader';
import { IInvitationService } from 'src/types/InvitationService';
import { IUpdateUserData, IUser } from 'src/types/User';
import { IPasswordService } from 'src/types/PasswordService';
import { IUserService } from 'src/types/UserService';
import { IReconfirmAccountService } from 'src/types/ReconfirmAccountService';

export interface IAuthStoreState {
  user?: IUser;
  authenticationToken: string;
}

export class AuthStore {
  state: IAuthStoreState = {
    user: undefined,
    authenticationToken: '',
  };

  authService: IAuthService;
  invitationService: IInvitationService;
  passwordService: IPasswordService;
  userService: IUserService;
  reconfirmAccountService: IReconfirmAccountService;

  constructor() {
    makeObservable(this, {
      state: observable,
      login: action.bound,
      logout: action.bound,
      init: action.bound,
      updateUser: action.bound,
    });

    this.authService = serviceLoader.load('authService');
    this.invitationService = serviceLoader.load('invitationService');
    this.passwordService = serviceLoader.load('passwordService');
    this.userService = serviceLoader.load('userService');
    this.reconfirmAccountService = serviceLoader.load(
      'reconfirmAccountService'
    );
    this.init();
  }

  init(): void {
    try {
      const { user, authenticationToken } =
        this.authService.getAuthenticationData();

      this.state.user = user;
      this.state.authenticationToken = authenticationToken;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.warn(error);
      }
    }
  }

  async login(email: string, password: string): Promise<void> {
    const { authenticationToken, user } = await this.authService.login(
      email,
      password
    );

    runInAction(() => {
      this.state.authenticationToken = authenticationToken;
      this.state.user = user;
    });
  }

  async logout(): Promise<void> {
    await this.authService.logout();

    runInAction(() => {
      this.state.authenticationToken = '';
      this.state.user = undefined;
    });
  }

  async acceptInvitation(
    invitationToken: string,
    password: string
  ): Promise<void> {
    await this.invitationService.acceptInvitation(invitationToken, password);
  }

  async reconfirmAccount(confirmationToken: string): Promise<void> {
    await this.reconfirmAccountService.reconfirmAccount(confirmationToken);
  }

  async forgotPassword(email: string): Promise<void> {
    await this.passwordService.forgotPassword(email);
  }

  async resetPassword(
    resetPasswordToken: string,
    password: string
  ): Promise<void> {
    await this.passwordService.resetPassword(resetPasswordToken, password);
  }

  async updateUser(user: IUpdateUserData): Promise<void> {
    await this.userService.updateUser(user);
    const currentUser = this.state.user;

    runInAction(() => {
      if (!currentUser) {
        throw new Error('User is not logged in');
      }

      currentUser.firstName = user.firstName;
      currentUser.lastName = user.lastName;
      currentUser.email = user.email;
    });
  }
}

import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import {
  InsulinDosageForm,
  InsulinDosageFormData,
} from 'src/components/forms/InsulinDosageForm';
import {
  useCreateInsulinDosage,
  usePatientInsulinDosage,
} from 'src/hooks/InsulinDosageHook';
import {
  IInjectionSetting,
  IInsulinPumpSetting,
  INewInsulinDosageData,
} from 'src/types/InsulinDosage';

export const SinglePatientPageInsulinDosage = observer(
  function (): JSX.Element {
    const { patientId } = useParams<{ patientId: string }>();
    const [insulinDosage] = usePatientInsulinDosage({
      patientId,
      fetchImmediately: true,
    });

    const [createInsulinDosage, { isLoading }] = useCreateInsulinDosage();

    const initialFormValues: InsulinDosageFormData = useMemo(() => {
      const { injectionSettings = [], insulinPumpSettings = [] } =
        insulinDosage || {};

      return {
        injectionSettings: injectionSettings.reduce(
          (acc, curr) => ({
            ...acc,
            [dayjs(curr.timeFrom, 'HH:mm').format('HH:mm')]: curr,
          }),
          {} as Record<string, IInjectionSetting>
        ),
        insulinPumpSettings: insulinPumpSettings.reduce(
          (acc, curr) => ({
            ...acc,
            [dayjs(curr.time, 'HH:mm').format('H:mm')]: curr,
          }),
          {} as Record<string, IInsulinPumpSetting>
        ),
      };
    }, [insulinDosage]);

    const handleSave = ({
      injectionSettings,
      insulinPumpSettings,
    }: InsulinDosageFormData) => {

      const injectionSettingsObject = Object.values(
        injectionSettings
      ) as INewInsulinDosageData['injectionSettings'];

      const insulinPumpObject = Object.values(
        insulinPumpSettings
      ) as INewInsulinDosageData['insulinPumpSettings'];

      // don't save empty dosage settings

      const filteredInjectionSettingsObject = injectionSettingsObject.filter(({ basalInsulin, correctionFactor, icRatio }) => {
        return !!basalInsulin || !!correctionFactor || !!icRatio;
      });

      const filteredInsulinPumpObject = insulinPumpObject.filter(({ basal, ic, isf }) => {
        return !!basal || !!ic || !!isf;
      });

      try {
        createInsulinDosage(patientId, {
          injectionSettings: filteredInjectionSettingsObject,
          insulinPumpSettings: filteredInsulinPumpObject,
        });
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <InsulinDosageForm
        insulinDosageCreatedAt={insulinDosage?.createdAt}
        defaultValues={initialFormValues}
        onSubmit={handleSave}
        isLoading={isLoading}
      />
    );
  }
);

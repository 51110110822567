import { createContext, useContext } from 'react';
import { AlertStore } from './AlertStore';
import { AuthStore } from './AuthStore';
import { ConsultationStore } from './ConsultationStore';
import { InsulinDosageStore } from './InsulinDosageStore';
import { PatientStore } from './PatientStore';

export type Stores = {
  authStore: AuthStore;
  patientStore: PatientStore;
  consultationStore: ConsultationStore;
  alertStore: AlertStore;
  insulinDosageStore: InsulinDosageStore;
};

export const createStores = (): Stores => {
  const authStore = new AuthStore();
  const alertStore = new AlertStore();

  return {
    authStore,
    alertStore,
    patientStore: new PatientStore(authStore),
    consultationStore: new ConsultationStore(authStore),
    insulinDosageStore: new InsulinDosageStore(authStore),
  };
};

export const StoreContext = createContext(createStores());
export const useStores = (): Stores => useContext(StoreContext);

import { axiosService } from './AxiosService';

export const forgotPassword = async (email: string): Promise<void> =>
  axiosService.getPublicInstance().post('/password', { user: { email } });

export const resetPassword = async (
  resetPasswordToken: string,
  password: string
): Promise<void> =>
  axiosService.getPublicInstance().put('/password', {
    user: {
      reset_password_token: resetPasswordToken,
      password,
      password_confirmation: password,
    },
  });

export const passwordService = Object.freeze({
  forgotPassword,
  resetPassword,
});

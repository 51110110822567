import {
  action,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
} from 'mobx';
import { IPatient } from 'src/types/Patient';
import { IPatientService } from 'src/types/PatientService';
import { SortParams } from 'src/types/SortParams';
import { AuthStore } from './AuthStore';
import { DefaultStore } from './DefaultStore';
import { serviceLoader } from './ServiceLoader';

interface PatientStoreState {
  patients: ObservableMap<number, IPatient>;
  meta: {
    total: number;
  };
}

export class PatientStore extends DefaultStore {
  state: PatientStoreState = {
    patients: new ObservableMap(),
    meta: {
      total: 0,
    },
  };

  patientService: IPatientService;

  constructor(authStore: AuthStore) {
    super(authStore);

    makeObservable(this, {
      state: observable,
      getPatients: action.bound,
      searchPatients: action.bound,
      updatePatient: action.bound,
      getPatient: action.bound,
      deletePatient: action.bound,
      clear: action.bound,
    });

    this.patientService = serviceLoader.load('patientService');
  }

  async getPatients(
    page: number,
    sort: SortParams
  ): Promise<{ patients: IPatient[]; total: number } | undefined> {
    const { patients, meta } = await this.patientService.getPatients(
      page,
      sort
    );

    runInAction(() => {
      this.state.meta = meta;
      this.state.patients.merge(
        patients.map((patient) => [patient.id, patient])
      );
    });

    return { patients, total: meta.total };
  }

  async getPatient(patientId: number): Promise<IPatient> {
    const patient = await this.patientService.get(patientId);

    runInAction(() => {
      this.state.patients.set(patient.id, patient);
    });

    return patient;
  }

  async searchPatients(searchQuery: string): Promise<{ patients: IPatient[] }> {
    const { patients } = await this.patientService.searchPatients(searchQuery);

    runInAction(() => {
      this.state.patients.merge(
        patients.map((patient) => [patient.id, patient])
      );
    });

    return { patients };
  }

  async createPatient(patientData: Omit<IPatient, 'id'>): Promise<IPatient> {
    const patient = await this.patientService.create(patientData);

    runInAction(() => {
      this.state.patients.set(patient.id, patient);
    });

    return patient;
  }

  async updatePatient(patient: IPatient): Promise<void> {
    const updatedPatient = await this.patientService.update(patient);

    runInAction(() => {
      this.state.patients.set(updatedPatient.id, updatedPatient);
    });
  }

  async deletePatient(patientId: number): Promise<void> {
    await this.patientService.delete(patientId);

    runInAction(() => {
      this.state.patients.delete(patientId);
    });
  }

  clear(): void {
    this.state.patients.clear();
    this.state.meta.total = 0;
  }
}

import { SxProps, Theme } from '@mui/system';

export const alertsContainerStyles: SxProps<Theme> = {
  zIndex: ({ zIndex }) => (zIndex as Record<string, number>).snackbar,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
};

export const alertStyles: SxProps<Theme> = {
  textAlign: 'center',
  justifyContent: 'center',
  '& .MuiAlert-action': {
    position: 'absolute',
    right: 16,
    marginLeft: 0,
  },
};

import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { object } from 'yup';
import { FormComponentProps } from 'src/types/FormComponent';
import {
  InsulinPumpSettingsInput,
  insulinPumpSettingValidation,
} from 'src/components/InsulinPumpSettingsInput';
import { InjectionSettingInputData } from 'src/components/InjectionSettingsInput/InjectionSettingItem';
import { InsulinPumpSettingsInputData } from 'src/components/InsulinPumpSettingsInput/InsulinPumpSettingsInputSlot';
import {
  InjectionSettingsInput,
  injectionSettingValidation,
} from 'src/components/InjectionSettingsInput';
import { Box, Typography } from '@mui/material';
import { DefaultButton } from 'src/components/DefaultButton';
import {
  dosageActionStyles,
  dosageSectionStyles,
  sectionHeadingStyles,
} from './styles';
import dayjs from 'dayjs';

export type InsulinDosageFormData = {
  injectionSettings: Record<string, InjectionSettingInputData>;
  insulinPumpSettings: Record<string, InsulinPumpSettingsInputData>;
};

export type InsulinDosageFormProps =
  FormComponentProps<InsulinDosageFormData> & {
    insulinDosageCreatedAt?: string;
  };

const validationSchema = object().shape({
  insulinPumpSettings: object().test(
    'isValid',
    'Invalid pump settings',
    (value) =>
      Object.values(value).every((setting) =>
        insulinPumpSettingValidation.isValidSync(setting)
      )
  ),
  injectionSettings: object().test(
    'isValid',
    'Invalid injection settings',
    (value) =>
      Object.values(value).every((setting) =>
        injectionSettingValidation.isValidSync(setting)
      )
  ),
});

const hasChanges = (
  initialValues: InsulinDosageFormData,
  values: InsulinDosageFormData
) => JSON.stringify(initialValues) !== JSON.stringify(values);

export function InsulinDosageForm({
  onSubmit,
  insulinDosageCreatedAt,
  defaultValues = {},
  isLoading,
}: InsulinDosageFormProps): JSX.Element {
  const initialValues: InsulinDosageFormData = useMemo(
    () => ({
      injectionSettings: defaultValues.injectionSettings || {},
      insulinPumpSettings: defaultValues.insulinPumpSettings || {},
    }),
    [defaultValues]
  );

  const lastUpdatedAt = insulinDosageCreatedAt && (
    <Typography>
      <strong>Last updated</strong>:{' '}
      {dayjs(insulinDosageCreatedAt).format('DD/MM/YYYY')}
    </Typography>
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false} // Validate on submit only
    >
      {({ values, handleChange, errors }) => (
        <Form>
          <Box sx={dosageSectionStyles}>
            <Box sx={sectionHeadingStyles}>
              <Typography variant="h2">Insulin Pump Settings</Typography>
              {lastUpdatedAt}
            </Box>
            <InsulinPumpSettingsInput
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              error={errors.injectionSettings as any}
              name="insulinPumpSettings"
              value={values.insulinPumpSettings}
              onChange={handleChange('insulinPumpSettings')}
            />
          </Box>
          <Box sx={dosageSectionStyles}>
            <Box sx={sectionHeadingStyles}>
              <Typography variant="h2">
                Multiple Daily Injection Settings
              </Typography>
              {lastUpdatedAt}
            </Box>
            <InjectionSettingsInput
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              error={errors.injectionSettings as any}
              name="injectionSetting"
              value={values.injectionSettings}
              onChange={handleChange('injectionSettings')}
            />
          </Box>
          <Box sx={dosageActionStyles}>
            <DefaultButton
              disabled={
                !hasChanges(defaultValues as InsulinDosageFormData, values)
              }
              type="submit"
              isLoading={isLoading}
            >
              Save
            </DefaultButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

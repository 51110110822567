import { SxProps } from '@mui/system';

export const dialogStyles: SxProps = {
  minWidth: '550px',
  padding: 3,
  '& > .MuiDialogContent-root': {
    padding: 0,
  },
  '& > .MuiDialogActions-root': {
    justifyContent: 'space-between',
    padding: 0,
    paddingTop: 5,
  },
};

export const dialogTitleStyles: SxProps = {
  padding: 0,
  paddingBottom: 2,
};

import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ConsultationTable } from 'src/components/tables/ConsultationTable';
import { useFetchConsultations } from 'src/hooks/ConsultationHook';
import { SortParams } from 'src/types/SortParams';

export const SinglePatientPageConsultation = observer(function (): JSX.Element {
  const { patientId } = useParams<{ patientId: string }>();
  const history = useHistory();
  const [sort, setSort] = useState<SortParams>({
    order: 'desc',
    orderBy: 'consultationDate',
  });
  const [page, setPage] = useState(0);
  const [{ consultations, total }, { refetch }] = useFetchConsultations({
    fetchImmediately: true,
    patientId,
    initialSort: {
      orderBy: 'consultationDate',
      order: 'desc',
    },
  });

  const handlePageChange = useCallback(
    async (_, page: number) => {
      const parsedPatientId = parseInt(patientId);
      await refetch(parsedPatientId, page + 1, sort);

      setPage(page);
    },
    [refetch, sort, patientId]
  );

  const handleSort = useCallback(
    async (sort: SortParams) => {
      const parsedPatientId = parseInt(patientId);
      setSort(sort);

      await refetch(parsedPatientId, page + 1, sort);
    },
    [setSort, refetch, page, patientId]
  );

  const handleConsultationClick = useCallback(
    (consultation) => {
      history.push(
        `/dashboard/patients/${patientId}/consultation/${consultation.id}`
      );
    },
    [history, patientId]
  );

  return (
    <div>
      {consultations.length > 0 && (
        <ConsultationTable
          consultations={consultations}
          onSort={handleSort}
          paginationProps={{
            onPageChange: handlePageChange,
            page,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            count: total,
          }}
          onRowClick={handleConsultationClick}
          {...sort}
        />
      )}
    </div>
  );
});

import { SxProps } from '@mui/system';

export const formSectionStyles: SxProps = {
  paddingBottom: 7,
};

export const actionContainerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

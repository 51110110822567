import { Box, debounce, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DefaultTextField } from 'src/components/FormikTextField';
import { yupValidationErrorToObject } from 'src/utils/validation';
import { ValidationError } from 'yup';
import { slotInputLabelStyles, slotInputStyles } from './styles';
import { insulinPumpSettingValidation } from './validation';

export type InsulinPumpSettingsInputData = {
  time: string;
  basal?: number;
  isf?: number;
  ic?: number;
};

type InsulinPumpSettingsInputSlotProp = {
  time: string;
  value?: InsulinPumpSettingsInputData;
  onChange: (value: InsulinPumpSettingsInputData) => void;
};

export function InsulinPumpSettingsInputSlot({
  time,
  value,
  onChange,
}: InsulinPumpSettingsInputSlotProp): JSX.Element {
  const { basal, isf, ic } = value || {};
  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined
  );

  const handleValidation = useMemo(
    () =>
      debounce(async (value: InsulinPumpSettingsInputData) => {
        try {
          await insulinPumpSettingValidation.validate(value, {
            abortEarly: false,
          });
          setErrors(undefined);
        } catch (error) {
          setErrors(yupValidationErrorToObject(error as ValidationError));
        }
      }, 500),
    [setErrors]
  );

  const handleInputChange = async (event: React.ChangeEvent) => {
    const { name, value } = event.target as HTMLInputElement;

    if (isNaN(Number(value))) {
      return;
    }

    const attribute = name.split('_')[1];
    const newValue = { time, basal: basal, isf, ic, [attribute]: value };

    onChange(newValue);
    handleValidation(newValue);
  };

  return (
    <Box>
      <Box>
        <Typography sx={slotInputLabelStyles}>{time}</Typography>
      </Box>
      <Box sx={slotInputStyles}>
        <DefaultTextField
          onChange={handleInputChange}
          name={`${time}_basal`}
          value={basal || ''}
          error={!!errors && !!errors['basal']}
        />
      </Box>
      <Box sx={slotInputStyles}>
        <DefaultTextField
          onChange={handleInputChange}
          name={`${time}_ic`}
          value={ic || ''}
          error={!!errors && !!errors['ic']}
        />
      </Box>
      <Box sx={slotInputStyles}>
        <DefaultTextField
          onChange={handleInputChange}
          name={`${time}_isf`}
          value={isf || ''}
          error={!!errors && !!errors['isf']}
        />
      </Box>
    </Box>
  );
}

import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    charcoal: {
      main: string;
      secondary: string;
    };
  }

  interface PaletteOptions {
    charcoal: {
      main: string;
      secondary: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#00ADB4',
      light: '#85D0D',
      contrastText: '#fff',
    },
    text: {
      primary: '#474747',
      secondary: '#8E8E8E',
      disabled: '#C4C4C4',
    },
    charcoal: {
      main: '#727272',
      secondary: '#9F9F9F',
    },
    divider: '#D7D5D5',
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: 'Montserrat',
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: '3.5rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '1.25',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '1.75',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
});

import { authService } from 'src/services/AuthService';
import { consultationService } from 'src/services/ConsultationService';
import { insulinDosageService } from 'src/services/InsulinDosageService';
import { invitationService } from 'src/services/InvitationService';
import { passwordService } from 'src/services/PasswordService';
import { patientService } from 'src/services/PatientService';
import { reconfirmAccountService } from 'src/services/ReconfirmAccountService';
import { userService } from 'src/services/UserService';
import { IAuthService } from 'src/types/AuthService';
import { IConsultationService } from 'src/types/ConsultationService';
import { IInsulinDosageService } from 'src/types/InsulinDosageService';
import { IInvitationService } from 'src/types/InvitationService';
import { IPasswordService } from 'src/types/PasswordService';
import { IPatientService } from 'src/types/PatientService';
import { IReconfirmAccountService } from 'src/types/ReconfirmAccountService';
import { IUserService } from 'src/types/UserService';

interface IStoreServices {
  authService: IAuthService;
  invitationService: IInvitationService;
  patientService: IPatientService;
  passwordService: IPasswordService;
  consultationService: IConsultationService;
  insulinDosageService: IInsulinDosageService;
  userService: IUserService;
  reconfirmAccountService: IReconfirmAccountService;
}

const services: IStoreServices = {
  authService,
  invitationService,
  patientService,
  passwordService,
  consultationService,
  insulinDosageService,
  userService,
  reconfirmAccountService,
};

export const serviceLoader = {
  load: <T extends keyof IStoreServices>(serviceName: T): IStoreServices[T] => {
    const service = services[serviceName];

    if (!service) {
      throw new Error(`${serviceName} not found`);
    }

    return service;
  },
};

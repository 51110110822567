import { TableCell } from '@mui/material';
import dayjs from 'dayjs';
import { IConsultation } from 'src/types/Consultation';
import { DefaultTableRow } from 'src/components/tables/DefaultTable';
import { useCallback } from 'react';

type ConsultationTableRowProp = {
  consultation: IConsultation;
  onRowClick: (consultation: IConsultation) => void;
};

export function ConsultationTableRow({
  consultation,
  onRowClick,
}: ConsultationTableRowProp): JSX.Element {
  const {
    consultationDate,
    user: { firstName, lastName },
    hbA1c,
  } = consultation;

  const handleClick = useCallback(() => {
    onRowClick(consultation);
  }, [onRowClick, consultation]);

  return (
    <DefaultTableRow onClick={handleClick}>
      <TableCell>{dayjs(consultationDate).format('DD/MM/YYYY')}</TableCell>
      <TableCell>
        {firstName} {lastName}
      </TableCell>
      <TableCell>{hbA1c ? `${hbA1c}%` : ''}</TableCell>
    </DefaultTableRow>
  );
}

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import {
  slotLabelContainerStyles,
  slotLabelItemStyles,
  labelColumnStyles,
} from './styles';

export function InsulinPumpSettingsInputLabel(): JSX.Element {
  return (
    <Grid sx={labelColumnStyles} display="flex" item>
      <Box sx={slotLabelContainerStyles}>
        <Box sx={slotLabelItemStyles}>Basal</Box>
        <Box sx={slotLabelItemStyles}>I/C</Box>
        <Box sx={slotLabelItemStyles}>ISF</Box>
      </Box>
    </Grid>
  );
}

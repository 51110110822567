import { SxProps } from '@mui/system';

export const publicImageStyles: SxProps = {
  height: '100vh',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  },
};

export const rightColStyles: SxProps = {
  display: 'flex',
};

export const mainContentContainerStyles: SxProps = {
  paddingLeft: '140px',
  paddingRight: '140px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const mainContentStyles: SxProps = {
  width: '100%',
};

export const mainContentLogoStyles: SxProps = {
  svg: {
    maxWidth: '168px',
    height: 'auto',
    marginBottom: 2,
  },
};

export const mainContentBoxStyles: SxProps = {
  width: '100%',
  maxWidth: '500px',
};

export const mainHeaderStyles: SxProps = {
  paddingBottom: 7,
  h3: {
    paddingBottom: 1,
  },
};

export const subtitleStyles: SxProps = {
  fontWeight: 500,
};

import { Box, IconButton, Menu } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  dashboardLayoutHeaderMenuListStyles,
  dashboardLayoutHeaderMenuStyles,
} from './styles';
import { ReactComponent as Logout } from 'src/assets/svgs/log-out.svg';
import { ReactComponent as Settings } from 'src/assets/svgs/settings.svg';
import { ReactComponent as Users } from 'src/assets/svgs/users.svg';
import { DashboardLayoutHeaderMenuItem } from './DashboardLayoutHeaderMenuItem';
import { observer } from 'mobx-react-lite';
import { useAuth } from 'src/hooks/AuthHook';

export const DashboardLayoutHeaderMenu = observer(function (): JSX.Element {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const buttonRef = useRef(null);
  const { logout } = useAuth();

  const handleClose = useCallback(() => {
    setIsMenuOpened(false);
  }, [setIsMenuOpened]);

  const handleOpen = useCallback(() => {
    setIsMenuOpened(true);
  }, [setIsMenuOpened]);

  const handleLogout = useCallback(() => {
    setIsMenuOpened(false);
    logout();
  }, [setIsMenuOpened, logout]);

  return (
    <Box>
      <IconButton title="menu" onClick={handleOpen} ref={buttonRef}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={buttonRef?.current}
        open={isMenuOpened}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: dashboardLayoutHeaderMenuStyles }}
        MenuListProps={{ sx: dashboardLayoutHeaderMenuListStyles }}
      >
        <DashboardLayoutHeaderMenuItem
          onClick={handleClose}
          icon={<Users />}
          url="/dashboard"
          content={'Patients'}
        />
        <DashboardLayoutHeaderMenuItem
          onClick={handleClose}
          icon={<Settings />}
          content={'Account'}
          url="/dashboard/account"
        />
        <DashboardLayoutHeaderMenuItem
          onClick={handleLogout}
          icon={<Logout />}
          content={'Logout'}
        />
      </Menu>
    </Box>
  );
});

import { useCallback, useEffect } from 'react';
import { useStores } from 'src/stores';
import { FetchDataHook, HandlerHook } from 'src/types/Hook';
import { IInsulinDosage, INewInsulinDosageData } from 'src/types/InsulinDosage';
import { useAsyncState } from './AsyncStateHook';

export const usePatientInsulinDosage: FetchDataHook<
  IInsulinDosage | undefined,
  { patientId: string },
  (patientId: string) => Promise<void>
> = (params) => {
  const { fetchImmediately = true, patientId } = params || {};
  const { insulinDosageStore } = useStores();
  const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;
  const insulinDosage =
    insulinDosageStore.state.patientInsulinDosages.get(parsedPatientId);
  const { state, start, finish } = useAsyncState();
  const { started } = state;
  const foundInsulinDosage = !!insulinDosage;

  const refetch = useCallback(async () => {
    if (!parsedPatientId) {
      finish({
        error: 'Invalid patient id',
      });
    }

    try {
      start();
      await insulinDosageStore.getPatientInsulinDosage(parsedPatientId);

      finish();
    } catch (error) {
      finish({
        error:
          (error as Error)?.message ||
          `Could not get patient with id ${parsedPatientId}`,
      });
    }
  }, [insulinDosageStore, parsedPatientId, finish, start]);

  useEffect(() => {
    if (!fetchImmediately || started || foundInsulinDosage) {
      return;
    }

    refetch();
  }, [foundInsulinDosage, started, fetchImmediately, refetch]);

  return [insulinDosage, { ...state, refetch }];
};

export const useCreateInsulinDosage: HandlerHook<
  (
    patientId: string,
    insulinDosageData: INewInsulinDosageData
  ) => Promise<IInsulinDosage | undefined>
> = () => {
  const { state, start, finish } = useAsyncState();
  const { alertStore, insulinDosageStore } = useStores();

  const handleSubmit = useCallback(
    async (patientId: string, insulinDosageData: INewInsulinDosageData) => {
      const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;

      if (!parsedPatientId) {
        alertStore.addAlert({
          content: `Invalid patient id`,
          type: 'error',
        });

        finish({
          error: 'Invalid patient id',
        });
      }

      try {
        start();
        const insulinDosage =
          await insulinDosageStore.createPatientInsulinDosage(
            parsedPatientId,
            insulinDosageData
          );

        alertStore.addAlert({
          content: `Insulin dosage has been updated successfully`,
          type: 'success',
        });

        finish();

        return insulinDosage;
      } catch (error) {
        const errorMessage = `Could not update insulin dosage`;

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        finish({
          error: errorMessage,
        });
      }
    },
    [start, finish, insulinDosageStore, alertStore]
  );

  return [handleSubmit, { ...state }];
};

import { SxProps } from '@mui/system';

export const passwordGuideStyles: SxProps = {
  paddingBottom: 6,
  fontWeight: 500,
};

export const errorStyles: SxProps = {
  paddingBottom: 2,
};

import { SxProps } from '@mui/system';

export const pumpSettingsRowStyles: SxProps = {
  '&:not(:last-child)': {
    paddingBottom: 4,
  },
};

export const slotInputStyles: SxProps = {
  '&:not(:last-child)': {
    paddingBottom: 2,
  },
};

export const slotInputLabelStyles: SxProps = {
  fontSize: '0.75rem',
  lineHeight: 1.25,
  paddingBottom: 1.5,
  fontWeight: 700,
};

export const slotLabelContainerStyles: SxProps = {
  paddingTop: 3,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

export const slotLabelItemStyles: SxProps = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
};

export const labelColumnStyles: SxProps = {
  width: 120,
};

import { IInvitationService } from 'src/types/InvitationService';
import { axiosService } from './AxiosService';

export const acceptInvitation = async (
  invitationToken: string,
  password: string
): Promise<void> =>
  axiosService.getPublicInstance().put('/invitation', {
    user: {
      invitation_token: invitationToken,
      password,
      password_confirmation: password,
    },
  });

export const invitationService: IInvitationService = Object.freeze({
  acceptInvitation,
});

import { SxProps, Theme } from '@mui/system';

export const tableContainerStyles: SxProps<Theme> = {
  background: 'white',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: ({ palette }) => palette.divider,
  boxShadow: '0px 0px 16px 2px rgba(71, 71, 71, 0.16)',
  overflow: 'hidden',
};

export const tableHeaderStyles: SxProps<Theme> = {
  th: {
    color: ({ palette }) => palette.charcoal.main,
    fontWeight: 700,
    borderBottomColor: ({ palette }) => palette.divider,
  },
};

export const rowStyles: SxProps<Theme> = {
  td: {
    borderBottomColor: ({ palette }) => palette.divider,
  },
};

export const rowClickableStyles: SxProps<Theme> = {
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: ({ palette }) => palette.divider,
  },
};

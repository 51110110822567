import { SxProps, Theme } from '@mui/system';

export const pageHeadingStyles: SxProps = {
  display: 'flex',
  alignItems: 'flex-end',
  paddingBottom: 8,
};

export const pageHeadingLeftStyles: SxProps = {
  flex: 1,
};

export const pageHeadingRightStyles: SxProps = {
  display: 'flex',
};

export const pageHeadingSummaryButtonStyles: SxProps<Theme> = {
  marginRight: 3,
  display: 'flex',
  justifyContent: 'space-between',
  svg: {
    marginLeft: 7,
    path: {
      stroke: ({ palette }) => palette.charcoal.main,
    },
  },
};

export const singlePatientTabStyles: SxProps<Theme> = {
  flex: '1 1 25%',
  minWidth: '25%',
};

export const singlePatientsTabPanelStyles: SxProps<Theme> = {
  borderBottom: '1px solid',
  borderBottomColor: ({ palette }) => palette.divider,
};

export const singlePatientsTabContentStyles: SxProps<Theme> = {
  paddingTop: 5,
};

export const dosageActions: SxProps<Theme> = {};

import { SxProps, Theme } from '@mui/system';

export const pageHeadingStyles: SxProps = {
  paddingBottom: 8,
};

export const tabStyles: SxProps<Theme> = {
  flex: '1 1 25%',
  minWidth: '25%',
};

export const tabPanelStyles: SxProps<Theme> = {
  borderBottom: '1px solid',
  borderBottomColor: ({ palette }) => palette.divider,
};

export const tabContentStyles: SxProps<Theme> = {
  paddingTop: 5,
};

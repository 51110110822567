import { DialogActions, DialogContent, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultButton } from 'src/components/DefaultButton';
import { DefaultDialog } from 'src/components/dialogs/DefaultDialog';
import { useDeletePatient } from 'src/hooks/PatientHook';
import { IPatient } from 'src/types/Patient';

export type SinglePatientDeleteDialogProps = {
  patient: IPatient;
  isOpened: boolean;
  onClose: () => void;
};

export const SinglePatientDeleteDialog = observer(function ({
  patient,
  isOpened,
  onClose,
}: SinglePatientDeleteDialogProps): JSX.Element {
  const [deletePatient, deleteState] = useDeletePatient();
  const history = useHistory();

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      await deletePatient(patient.id);
      history.push('/dashboard');
    } finally {
      onClose();
    }
  }, [patient.id, deletePatient, onClose, history]);

  return (
    <DefaultDialog title="Delete patient" open={isOpened} onClose={onClose}>
      <DialogContent>
        <Typography>Are you sure you want to delete this patient?</Typography>
      </DialogContent>
      <DialogActions>
        <DefaultButton onClick={onClose} color="primary">
          Go Back
        </DefaultButton>
        <DefaultButton
          isLoading={deleteState.isLoading}
          variant="outlined"
          color="error"
          onClick={handleDelete}
        >
          Delete
        </DefaultButton>
      </DialogActions>
    </DefaultDialog>
  );
});

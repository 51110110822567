import { reaction } from 'mobx';
import { AuthStore } from './AuthStore';

export class DefaultStore {
  authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;

    // clean up data if user is logged out for security purpose
    reaction(
      () => this.authStore.state.user?.email,
      (userEmail) => {
        if (!userEmail) {
          this.clear();
        }
      }
    );
  }

  // This method is supposed to be implemented by subclass
  clear(): void {
    console.warn('Clear method must be implemented');
  }
}

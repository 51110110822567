import { TextField, TextFieldProps } from '@mui/material';
import { inputStyles, inputWrapperStyles, labelStyles } from './styles';

export function DefaultTextField({
  inputProps,
  multiline,
  ...rest
}: TextFieldProps): JSX.Element {
  return (
    <TextField
      id={rest.id || rest.name}
      sx={inputWrapperStyles}
      inputProps={{ ...inputProps, sx: multiline ? undefined : inputStyles }}
      InputLabelProps={{ sx: labelStyles, ...rest.InputLabelProps }}
      multiline={multiline}
      {...rest}
    />
  );
}

import { SxProps } from '@mui/system';
import { Link, LinkProps } from 'react-router-dom';
import { Typography } from '@mui/material';
import { defaultLinkStyles } from './styles';

type DefaultLinkProp = LinkProps & { sx?: SxProps };

export function DefaultLink({ sx, ...rest }: DefaultLinkProp): JSX.Element {
  return (
    <Typography sx={defaultLinkStyles}>
      <Link {...rest} />
    </Typography>
  );
}

import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from 'mobx';
import { nanoid } from 'nanoid';
import { IAlert } from 'src/types/Alert';

interface IAlertStoreState {
  alerts: ObservableMap<string, IAlert>;
}

export class AlertStore {
  state: IAlertStoreState = {
    alerts: observable.map({}),
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      addAlert: action.bound,
      removeAlert: action.bound,
      alertsList: computed,
    });
  }

  addAlert(alert: Omit<IAlert, 'id'>, timeout: null | number = 5000): void {
    const id = nanoid();
    this.state.alerts.set(id, { id, ...alert });

    if (timeout !== null) {
      setTimeout(() => this.removeAlert(id), timeout);
    }
  }

  removeAlert(id: string): void {
    this.state.alerts.delete(id);
  }

  get alertsList(): IAlert[] {
    return Array.from(this.state.alerts.values());
  }
}

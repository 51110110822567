import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { mixed, number, object, string } from 'yup';
import { FormComponentProps } from 'src/types/FormComponent';
import { Box, Grid, Typography } from '@mui/material';
import { FormikDatePicker } from 'src/components/FormikDatePicker';
import dayjs from 'dayjs';
import { FormikTextField } from 'src/components/FormikTextField';
import { FormikSelect } from 'src/components/FormikSelect';
import {
  basalInsulinTypeOptions,
  bolusInsulinTypeOptions,
  cannulaTypeOptions,
  cgmUsageOptions,
  coeliacOptions,
  managementAdviceOptions,
  glycaemicPatternsOptions,
  homeSupportOptions,
  pumpSystemUsedOptions,
  insulinDeliveryOptions,
  insulinPumpTypeOptions,
  lineChangeOptions,
  medicalTeamOptions,
  nephropathyOptions,
  normalcyOptions,
  otherAttendeesOptions,
  severeOptions,
  siteOptions,
  supportOptions,
  thyroidOptions,
  typeOfCgmOptions,
  yesNoOptions,
} from 'src/utils/options';
import {
  fieldsRowStyles,
  formSectionStyles,
  sectionTitleStyles,
} from './styles';
import { DefaultButton } from 'src/components/DefaultButton';
import {
  calculateBasalInsulinDosePerKg,
  calculateBolusInsulinDose,
  calculateBolusInsulinDosePerKg,
  calculateDailyInsulinDose,
  calculateDailyInsulinDosePerKg,
  getBMI,
} from './helper';
import { IConsultation } from 'src/types/Consultation';

export type ConsultationFormData = Omit<
  IConsultation,
  'id' | 'user' | 'hbA1c' | 'hbA1cMmol'
> & {
  hbA1c?: number;
  hbA1cMmol?: number;
};

function emptyStringToNull(value: unknown, originalValue: string) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

function notANumberToNull(value: unknown, originalValue: string) {
  if (typeof originalValue === 'number' && isNaN(originalValue)) {
    return null;
  }
  return value;
}

export const validationSchema = object().shape({
  consultationDate: mixed().required('Consultation date is required'),
  height: number()
    .positive('Height must be positive')
    .transform(emptyStringToNull)
    .typeError('Height must be a number')
    .nullable(),
  weight: number()
    .positive('Weight must be positive')
    .transform(emptyStringToNull)
    .typeError('Weight must be a number')
    .nullable(),
  totalDailyInsulinDose: number()
    .transform(emptyStringToNull)
    .when(['insulinDelivery'], {
      is: (insulinDelivery: string) => ['insulin_pump'].includes(insulinDelivery),
      then: number()
        .positive('Total daily insulin dose must be positive')
        .typeError('Total daily insulin dose must be a number')
        .nullable(),
      })
    .nullable(),
  totalBasalInsulinDose: number()
    .positive('Total basal Insulin dose must be positive')
    .transform(emptyStringToNull)
    .typeError('Total basal Insulin dose must be a number')
    .nullable(),
  totalBolusInsulinDose: number()
    .transform(emptyStringToNull)
    .when(['insulinDelivery'], {
      is: (insulinDelivery: string) => ['bd', 'mdi'].includes(insulinDelivery),
      then: number()
        .positive('Total bolus Insulin dose must be positive')
        .typeError('Total bolus Insulin dose must be a number')
        .nullable(),
      })
    .nullable(),
  numberOfInjectionsPerDay: number()
    .integer('Number of injections/day must be an integer')
    .min(0)
    .transform(emptyStringToNull)
    .typeError('Number of injections/day must be a number')
    .nullable(),
  numberOfBloodGlucoseEntriesPerDay: number()
    .positive('Number of blood glucose entries/day must be positive')
    .transform(emptyStringToNull)
    .typeError('Number of blood glucose entries/day must be a number')
    .nullable(),
  averageAmountOfCarbohydrateEntriesPerDay: number()
    .positive('Average amount of carbohydrate entries/day must be positive')
    .transform(emptyStringToNull)
    .typeError('Average amount of carbohydrate entries/day must be a number')
    .nullable(),
  averageAmountOfCarbohydratesPerDay: number()
    .positive('Average amount of carbohydrates/day must be positive')
    .transform(emptyStringToNull)
    .typeError('Average amount of carbohydrates/day must be a number')
    .nullable(),
  hbA1c: number()
    .min(0)
    .max(100)
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .typeError('HbA1c% must be a number')
    .nullable(true),
  hbA1cMmol: number()
    .min(0)
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .typeError('HbA1c (mmol/l) must be a number')
    .nullable(true),
  variability: number()
    .min(0)
    .transform(emptyStringToNull)
    .typeError('Variability must be a number')
    .nullable(),
  timeInRange: number()
    .min(0)
    .max(100)
    .transform(emptyStringToNull)
    .typeError('Time in range must be a number')
    .nullable(),
  timeBelowRange: number()
    .min(0)
    .max(100)
    .transform(emptyStringToNull)
    .typeError('Time below range must be a number')
    .nullable(),
  timeAboveRange: number()
    .min(0)
    .max(100)
    .transform(emptyStringToNull)
    .typeError('Time above range must be a number')
    .nullable(),
  otherConditions: string()
    .max(100)
    .transform(emptyStringToNull)
    .nullable(),
  bloodPressureSystole: number()
    .positive('Blood pressure systole must be positive')
    .transform(emptyStringToNull)
    .typeError('Blood pressure systole must be a number')
    .nullable(),
  bloodPressureDiastole: number()
    .positive('Blood pressure diastole must be positive')
    .transform(emptyStringToNull)
    .typeError('Blood pressure diastole must be a number')
    .nullable(),
  homeSupportOther: string()
    .when(['homeSupport'], {
      is: (homeSupport: string) => homeSupport === 'other',
      then: string()
        .required("Home support other is required")
        .max(100, 'Field must not exceed 100 characters')
        .nullable()
      })
    .nullable(),
  glycaemicPatternsOther: string()
    .when(['glycaemicPatterns'], {
      is: (glycaemicPatterns: string[]) => glycaemicPatterns.includes('other'),
      then: string()
        .required("Glycaemic patterns other is required")
        .max(100, 'Field must not exceed 100 characters')
        .nullable()
      })
    .nullable(),
  foodBolusSpecificStrategies: string()
    .when(['managementAdvice'], {
      is: (managementAdvice: string[]) => managementAdvice.includes('specific_strategies'),
      then: string()
        .required("Food bolus specific strategies is required")
        .max(100, 'Field must not exceed 100 characters')
        .nullable()
      })
    .nullable(),
  daysInHospitalDueToDiabetesSinceLastVisit: number()
    .integer('Number of days must be an integer')
    .min(0)
    .transform(emptyStringToNull)
    .typeError('Number of days must be a number')
    .nullable(),
});

type ConsultationFormProps = FormComponentProps<ConsultationFormData> & {
  isLoading?: boolean;
  errors?: Record<string, string>;
};

export function ConsultationForm({
  onSubmit,
  defaultValues = {},
  errors,
}: ConsultationFormProps): JSX.Element {
  const initialValues = useMemo(
    () => ({
      consultationDate: dayjs().format('YYYY-MM-DD'),
      medicalTeamAttending: [],
      otherAttendees: [],
      nephropathy: [],
      thyroid: [],
      coeliac: [],
      cholesterol: [],
      retinopathy: [],
      sites: [],
      managementAdvice: [],
      glycaemicPatterns: [],
      hbA1c: '',
      hbA1cMmol: '',
      timeInRange: '',
      timeBelowRange: '',
      timeAboveRange: '',
      pumpWarranty: '',
      complicationScreenDueDate: '',
      nextConsultation: '',
      consultationSummary: '',
      sickDayManagementPlanDue: null,
      daysInHospitalDueToDiabetesSinceLastVisit: '',
      ...defaultValues,
    }),
    [defaultValues]
  );

  const today = useMemo(() => dayjs(), []);

  const actualTotalDailyInsulinDose = (formData: ConsultationFormData): number | undefined => {
    if (formData.insulinDelivery !== 'insulin_pump') {
      return calculateDailyInsulinDose(
        formData.totalBasalInsulinDose,
        formData.totalBolusInsulinDose
      );
    } else {
      return formData.totalDailyInsulinDose;
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues as ConsultationFormData}
      onSubmit={onSubmit}
      initialErrors={errors}
      initialTouched={errors}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box sx={formSectionStyles}>
            <Grid spacing={3} rowSpacing={4} container>
              <Grid item xs={12}>
                <Typography variant="h2">General</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  label="Date of consultation"
                  name="consultationDate"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Telemedicine"
                  name="telemedicine"
                  options={yesNoOptions}
                />
              </Grid>
              <Grid item xs={2}>
                <FormikTextField label="Height" name="height" />
              </Grid>
              <Grid item xs={2}>
                <FormikTextField label="Weight" name="weight" />
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                xs={2}
              >
                <Typography>
                  <strong>BMI</strong>
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  {values.height && values.weight
                    ? getBMI(values.height, values.weight)
                    : 'BMI'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Medical team attending"
                  name="medicalTeamAttending"
                  options={medicalTeamOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Other attendees"
                  name="otherAttendees"
                  options={otherAttendeesOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Multidisciplinary case meeting"
                  name="multidisciplinaryCaseMeeting"
                  options={yesNoOptions}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={formSectionStyles}>
            <Grid spacing={3} rowSpacing={4} container>
              <Grid item xs={12}>
                <Typography variant="h2">Insulin Management</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Insulin delivery"
                  name="insulinDelivery"
                  options={insulinDeliveryOptions}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Type of insulin pump"
                  name="insulinPumpType"
                  options={insulinPumpTypeOptions}
                  disabled={values.insulinDelivery !== 'insulin_pump'}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDatePicker
                  label="Pump warranty"
                  name="pumpWarranty"
                  disabled={values.insulinDelivery !== 'insulin_pump'}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Cannula type"
                  name="cannulaType"
                  options={cannulaTypeOptions}
                  disabled={values.insulinDelivery !== 'insulin_pump'}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Pump system used"
                  name="pumpSystemUsed"
                  options={pumpSystemUsedOptions}
                  disabled={values.insulinDelivery !== 'insulin_pump'}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Type of bolus insulin"
                  name="bolusInsulinType"
                  options={bolusInsulinTypeOptions}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Type of basal insulin"
                  name="basalInsulinType"
                  options={basalInsulinTypeOptions}
                />
              </Grid>
              <Grid item xs={3}>
                {values.insulinDelivery !== 'insulin_pump' ? (
                  <>
                    <Typography>
                      <strong>Total daily insulin dose (U)</strong>
                    </Typography>
                    <Typography>
                      {calculateDailyInsulinDose(
                        values.totalBasalInsulinDose,
                        values.totalBolusInsulinDose
                        )?.toFixed(2) || '00'}
                    </Typography>
                  </>
                ) : (
                  <FormikTextField
                    label="Total daily insulin dose (U)"
                    name="totalDailyInsulinDose"
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <FormikTextField
                  label="Total basal insulin dose (U)"
                  name="totalBasalInsulinDose"
              />
              </Grid>
              <Grid item xs={3}>
                {values.insulinDelivery !== 'insulin_pump' ? (
                  <FormikTextField
                    label="Total bolus insulin dose (U)"
                    name="totalBolusInsulinDose"
                  />
                ) : (
                  <>
                    <Typography>
                      <strong>Total bolus insulin dose (U)</strong>
                    </Typography>
                    <Typography>
                      {calculateBolusInsulinDose(
                        values.totalDailyInsulinDose,
                        values.totalBasalInsulinDose
                      )?.toFixed(2) || '00'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Typography>
                  <strong>Total daily insulin dose(U)/kg</strong>
                </Typography>
                <Typography>
                  {calculateDailyInsulinDosePerKg(
                    actualTotalDailyInsulinDose(values),
                    values.weight
                  ) || '00'}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  <strong>Total basal insulin dose (U)/kg</strong>
                </Typography>
                <Typography>
                  {calculateBasalInsulinDosePerKg(
                    values.totalBasalInsulinDose,
                    values.weight
                  ) || '00'}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  <strong>Total bolus insulin dose (U)/kg</strong>
                </Typography>
                <Typography>
                  {calculateBolusInsulinDosePerKg(
                    actualTotalDailyInsulinDose(values),
                    values.totalBasalInsulinDose,
                    values.weight
                  ) || '00'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Number of injections/day"
                  name="numberOfInjectionsPerDay"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Number of blood glucose entries/day"
                  name="numberOfBloodGlucoseEntriesPerDay"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Number of carbohydrate entries/day"
                  name="averageAmountOfCarbohydrateEntriesPerDay"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Total average carbohydrates (g)/day"
                  name="averageAmountOfCarbohydratesPerDay"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Line change interval (completed days)"
                  options={lineChangeOptions}
                  name="lineChangeIntervalCompletedDays"
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={formSectionStyles}>
            <Grid spacing={3} rowSpacing={4} container>
              <Grid item xs={12}>
                <Typography variant="h2">Glycaemic Control</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="CGM usage (%)"
                  options={cgmUsageOptions}
                  name="cgmUsage"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Type of CGM"
                  options={typeOfCgmOptions}
                  name="typeOfCgm"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Severe hypoglycaemia"
                  helperText="Episodes since last consultation"
                  options={severeOptions}
                  name="severeHypoglycaemia"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Severe DKA"
                  helperText="Episodes since last consultation"
                  options={severeOptions}
                  name="severeDka"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField label="HbA1c (%)" name="hbA1c" />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField label="HbA1c (mmol/l)" name="hbA1cMmol" />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  label="Variability (standard deviation)"
                  name="variability"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Time in range % (3.5-8.0mmol/L)"
                  name="timeInRange"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Time below range % (<3.5mmol/L)"
                  name="timeBelowRange"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Time above range % (>8.0 mmol/L)"
                  name="timeAboveRange"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Days in hospital due to diabetes since last visit"
                  name="daysInHospitalDueToDiabetesSinceLastVisit"
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={formSectionStyles}>
            <Grid container spacing={3} rowSpacing={4}>
              <Grid item xs={12}>
                <Typography variant="h2">Complication and Association Screen</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikDatePicker
                  label="Complication screen due date"
                  name="complicationScreenDueDate"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Nephropathy"
                  options={nephropathyOptions}
                  name="nephropathy"
                  multiple
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Thyroid"
                  options={thyroidOptions}
                  name="thyroid"
                  multiple
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Coeliac"
                  options={coeliacOptions}
                  name="coeliac"
                  multiple
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Cholesterol"
                  options={normalcyOptions}
                  name="cholesterol"
                  multiple
                />
              </Grid>
              <Grid item xs={3}>
                <FormikSelect
                  label="Retinopathy"
                  options={normalcyOptions}
                  name="retinopathy"
                  multiple
                />
              </Grid>
              <Grid item xs={3}>
                <FormikTextField
                  label="Other conditions"
                  name="otherConditions"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikTextField
                  label="Blood pressure systole (mmHg)"
                  name="bloodPressureSystole"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikTextField
                  label="Blood pressure diastole (mmHg)"
                  name="bloodPressureDiastole"
                />
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  label="Sick day management plan due date"
                  name="sickDayManagementPlanDue"
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={formSectionStyles}>
            <Grid container spacing={3} rowSpacing={4}>
              <Grid item xs={12}>
                <Typography variant="h2">Evaluation</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Sites"
                  name="sites"
                  options={siteOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="School support"
                  name="schoolSupportAssessment"
                  options={supportOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Home support"
                  name="homeSupport"
                  options={homeSupportOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Other (Please specify)"
                  name="homeSupportOther"
                  disabled={values.homeSupport !== 'other'}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Glycaemic patterns"
                  name="glycaemicPatterns"
                  options={glycaemicPatternsOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Other (Please specify)"
                  name="glycaemicPatternsOther"
                  disabled={values.glycaemicPatterns ? !values.glycaemicPatterns.includes('other') : true}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Management advice"
                  name="managementAdvice"
                  options={managementAdviceOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Specific strategies (Please specify)"
                  name="foodBolusSpecificStrategies"
                  disabled={values.managementAdvice ? !values.managementAdvice.includes('specific_strategies') : true}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelect
                  label="Settings adjusted"
                  name="settingsAdjusted"
                  options={yesNoOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDatePicker
                  label="Next consultation"
                  name="nextConsultation"
                  helperText="Leave blank if to be determined"
                  minDate={today}
                  clearable={true}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container sx={fieldsRowStyles}>
            <Grid item xs={12}>
              <Typography sx={sectionTitleStyles} variant="h2">
              Consultation summary
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormikTextField name="consultationSummary" multiline rows={4} />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DefaultButton type="submit">Save</DefaultButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

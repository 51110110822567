import { SxProps } from '@mui/system';

export const selectStyles: SxProps = {
  '& .MuiSelect-select': {
    paddingTop: '12.5px',
    paddingBottom: '12.5px',
  },
};

export const labelStyles: SxProps = {
  transform: 'translate(14px, 12.5px) scale(1)',
  '&.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
};

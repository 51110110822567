import { number, object, string } from 'yup';

function emptyStringToNull(value: unknown, originalValue: string) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

function notANumberToNull(value: unknown, originalValue: number) {
  if (typeof originalValue === 'number' && isNaN(originalValue)) {
    return null;
  }
  return value;
}

export const insulinPumpSettingValidation = object().shape({
  time: string().required(),
  basal: number()
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .min(0, 'Must be larger than 0')
    .nullable(),
  ic: number()
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .min(0, 'Must be larger than 0')
    .nullable(),
  isf: number()
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .min(0, 'Must be larger than 0')
    .nullable(),
}, [['isf', 'basal'], ['isf', 'ic'], ['basal', 'ic']]);

import { number, object, string } from 'yup';

function emptyStringToNull(value: unknown, originalValue: string) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }

  return value;
}

function notANumberToNull(value: unknown, originalValue: number) {
  if (typeof originalValue === 'number' && isNaN(originalValue)) {
    return null;
  }
  return value;
}

export const injectionSettingValidation = object().shape({
  timeFrom: string().required(),
  timeTo: string().required(),
  basalInsulin: number()
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .min(0, 'Must be larger than 0')
    .nullable(),
  icRatio: number()
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .min(0, 'Must be larger than 0')
    .nullable(),
  correctionFactor: number()
    .transform(emptyStringToNull)
    .transform(notANumberToNull)
    .min(0, 'Must be larger than 0')
    .nullable(),
}, [['basalInsulin', 'icRatio'], ['basalInsulin', 'correctionFactor'], ['icRatio', 'correctionFactor']]);

import { action, makeObservable, ObservableMap, runInAction } from 'mobx';
import { IConsultation } from 'src/types/Consultation';
import { IConsultationService } from 'src/types/ConsultationService';
import { SortParams } from 'src/types/SortParams';
import { AuthStore } from './AuthStore';
import { DefaultStore } from './DefaultStore';
import { serviceLoader } from './ServiceLoader';

interface ConsultationStoreState {
  patientConsultations: ObservableMap<number, IConsultation[]>;
  newConsultation?: IConsultation;
  meta: {
    total: number;
  };
}

export class ConsultationStore extends DefaultStore {
  state: ConsultationStoreState = {
    patientConsultations: new ObservableMap(),
    newConsultation: undefined,
    meta: {
      total: 0,
    },
  };

  consultationService: IConsultationService;

  constructor(authStore: AuthStore) {
    super(authStore);

    makeObservable(this, {
      getPatientConsultations: action.bound,
      getPatientConsultation: action.bound,
      createConsultation: action.bound,
      updateConsultation: action.bound,
      clear: action.bound,
      findPatientConsultationById: action.bound,
    });

    this.consultationService = serviceLoader.load('consultationService');
  }

  async getNewPatientConsultation(patientId: number): Promise<IConsultation> {
    const newConsultation = await this.consultationService.getNewPatientConsultation(patientId);

    runInAction(() => {
      this.state.newConsultation = newConsultation;
    });

    return newConsultation;
  }

  async getPatientConsultations(
    patientId: number,
    page: number,
    sort: SortParams
  ): Promise<{ consultations: IConsultation[]; total: number }> {
    const { total, consultations } =
      await this.consultationService.getPatientConsultations(
        patientId,
        page,
        sort
      );

    runInAction(() => {
      this.state.meta.total = total;
      this.state.patientConsultations.set(patientId, consultations);
    });

    return { consultations, total };
  }

  async getPatientConsultation(
    patientId: number,
    consultationId: number
  ): Promise<IConsultation> {
    const consultation = await this.consultationService.getPatientConsultation(
      patientId,
      consultationId
    );

    runInAction(() => {
      const consultations =
        this.state.patientConsultations.get(patientId) || [];

      this.state.patientConsultations.set(patientId, [
        ...consultations,
        consultation,
      ]);
    });

    return consultation;
  }

  async createConsultation(
    patientId: number,
    consultationData: Omit<IConsultation, 'id' | 'user'>
  ): Promise<IConsultation> {
    const consultation =
      await this.consultationService.createPatientConsultation(
        patientId,
        consultationData
      );

    runInAction(() => {
      const patientConsultations =
        this.state.patientConsultations.get(patientId) || [];
      patientConsultations.push(consultation);
    });

    return consultation;
  }

  async updateConsultation(
    patientId: number,
    consultationData: Omit<IConsultation, 'user'>
  ): Promise<IConsultation> {
    const consultation =
      await this.consultationService.updatePatientConsultation(
        patientId,
        consultationData
      );

    runInAction(() => {
      const patientConsultations =
        this.state.patientConsultations.get(patientId) || [];

      this.state.patientConsultations.set(patientId, [
        ...patientConsultations.filter(({ id }) => id !== consultation.id),
        consultation,
      ]);
    });

    return consultation;
  }

  clear(): void {
    this.state.patientConsultations.clear();
    this.state.meta.total = 0;
  }

  findPatientConsultationById(
    patientId: number,
    consultationId: number
  ): IConsultation | undefined {
    const consultations = this.state.patientConsultations.get(patientId);

    if (!consultations) {
      return undefined;
    }

    return consultations.find(
      (consultation) => consultation.id === consultationId
    );
  }
}

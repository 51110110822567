import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { object, ref, string } from 'yup';
import { FormComponentProps } from 'src/types/FormComponent';
import { FormikTextField } from 'src/components/FormikTextField';
import { Box, Grid } from '@mui/material';
import { DefaultButton } from 'src/components/DefaultButton';
import { formSectionStyles, actionContainerStyles } from './styles';
import { IUpdateUserData } from 'src/types/User';

export const validationSchema = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  email: string().required('Email is required'),
  currentPassword: string().required('Current password is required'),
  password: string()
    .min(12, 'Minimum 12 characters')
    .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?:.;_\-[\]()])/, {
      message:
        'Must include one uppercase, one lowercase, one number and one punctuation symbol',
    }),
  confirmationPassword: string().oneOf(
    [ref('password')],
    'Confirmation password does not match'
  ),
});

export function AccountForm({
  onSubmit,
  defaultValues = {},
  isLoading,
}: FormComponentProps<IUpdateUserData>): JSX.Element {
  const initialValues: IUpdateUserData = useMemo(
    () => ({
      id: (defaultValues as IUpdateUserData).id,
      firstName: defaultValues.firstName || '',
      lastName: defaultValues.lastName || '',
      email: defaultValues.email || '',
      password: '',
      passwordConfirmation: '',
      currentPassword: '',
    }),
    [defaultValues]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <Form>
          <Box sx={formSectionStyles}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormikTextField label="First name" name="firstName" />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField label="Last name" name="lastName" />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  label="Email"
                  type="email"
                  name="email"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Password"
                  type="password"
                  name="password"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  label="Confirm password"
                  type="password"
                  name="passwordConfirmation"
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  label="Type in current password"
                  type="password"
                  name="currentPassword"
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={actionContainerStyles}>
            <DefaultButton type="submit" isLoading={isLoading}>
              Save
            </DefaultButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

import { IUpdateUserData, IUser } from 'src/types/User';
import { IUserService } from 'src/types/UserService';
import { axiosService } from './AxiosService';

const transformUserRequest = (
  user: IUpdateUserData
): Record<string, string | number | undefined> => ({
  first_name: user.firstName,
  last_name: user.lastName,
  email: user.email,
  password: user.password || undefined,
  password_confirmation: user.passwordConfirmation || undefined,
  current_password: user.currentPassword,
});

const transformUserResponse = (
  data: Record<string, string | number>
): IUser => ({
  firstName: data.first_name as string,
  lastName: data.last_name as string,
  email: data.email as string,
  id: data.id as number,
});

const updateUser = (user: IUpdateUserData): Promise<IUser> =>
  axiosService
    .getSecuredInstance()
    .put(`/users/${user.id}`, {
      user: transformUserRequest(user),
    })
    .then((response) =>
      transformUserResponse(response.data as Record<string, string | number>)
    );

export const userService: IUserService = Object.freeze({
  updateUser,
});

import { AppBar, Box } from '@mui/material';
import {
  dashboardLayoutHeaderContentStyles,
  dashboardLayoutHeaderLeft,
  dashboardLayoutHeaderRight,
  dashboardLayoutHeaderStyles,
  logoContainerStyles,
} from './styles';
import { ReactComponent as Logo } from 'src/assets/svgs/logo.svg';
import { DashboardLayoutHeaderMenu } from './DashboardLayoutHeaderMenu';
import { PatientSearchBar } from 'src/components/PatientSearchBar';
import { Link } from 'react-router-dom';

export function DashboardLayoutHeader(): JSX.Element {
  return (
    <AppBar sx={dashboardLayoutHeaderStyles}>
      <Box sx={dashboardLayoutHeaderContentStyles}>
        <Box sx={dashboardLayoutHeaderLeft}>
          <Box sx={logoContainerStyles}>
            <Link to="/">
              <Logo title="logo" />
            </Link>
          </Box>
        </Box>
        <Box sx={dashboardLayoutHeaderRight}>
          <PatientSearchBar />
          <DashboardLayoutHeaderMenu />
        </Box>
      </Box>
    </AppBar>
  );
}

import dayjs from 'dayjs';
import { IPatient } from 'src/types/Patient';
import { IPatientService } from 'src/types/PatientService';
import { SortParams } from 'src/types/SortParams';
import { camelToSnakeCase } from 'src/utils/camelToSnakeCase';
import { axiosService } from './AxiosService';

export const transformPatientResponse = ({
  id,
  first_name,
  last_name,
  email,
  gender,
  date_of_birth,
  consultation_date,
  date_of_diagnosis,
  type_of_diabetes,
  patient_id,
  general_comments,
  dka_at_diagnosis,
  previous_severe_dka,
  previous_severe_hypoglycaemia,
  school,
  current_grade,
  school_plan_complete,
  school_plan_revision_due,
  university_course,
  work,
  partner,
  guardian_1_name,
  guardian_2_name,
  private_health_fund,
  type_1_diabetes_autoantibodies,
  other_medications,
  referring_doctor_name,
  referring_doctor_address,
  referral_due,
  team_care_arrangement_due,
}: Record<string, string | string[] | number>): IPatient => ({
  id: id as number,
  firstName: first_name as string,
  lastName: last_name as string,
  email: email as string,
  gender: gender as string,
  dateOfBirth: date_of_birth as string,
  dateOfDiagnosis: date_of_diagnosis as string,
  typeOfDiabetes: type_of_diabetes as string,
  consultationDate: consultation_date as string,
  patientId: patient_id as string,
  dkaAtDiagnosis: dka_at_diagnosis as string,
  previousSevereDka: previous_severe_dka as string,
  previousSevereHypoglycaemia: previous_severe_hypoglycaemia as string,
  school: school as string,
  currentGrade: current_grade as string,
  schoolPlanComplete: school_plan_complete as string,
  schoolPlanRevisionDue: school_plan_revision_due as string,
  universityCourse: university_course as string,
  work: work as string,
  partner: partner as string,
  guardian1Name: guardian_1_name as string,
  guardian2Name: guardian_2_name as string,
  generalComments: general_comments as string,
  privateHealthFund: private_health_fund as string,
  type1DiabetesAutoantibodies: type_1_diabetes_autoantibodies as string[],
  otherMedications: other_medications as string,
  referringDoctorName: referring_doctor_name as string,
  referringDoctorAddress: referring_doctor_address as string,
  referralDue: referral_due as string,
  teamCareArrangementDue: team_care_arrangement_due as string,
});

const transformPatientRequest = (
  patient: IPatient | Omit<IPatient, 'id'>
): Record<string, string | string[] | number | null | undefined> => ({
  id: (patient as IPatient).id || '',
  first_name: patient.firstName,
  last_name: patient.lastName,
  email: patient.email,
  gender: patient.gender,
  date_of_birth: patient.dateOfBirth
    ? dayjs(patient.dateOfBirth).format('YYYY-MM-DD')
    : null,
  date_of_diagnosis: patient.dateOfDiagnosis
    ? dayjs(patient.dateOfDiagnosis).format('YYYY-MM-DD')
    : null,
  type_of_diabetes: patient.typeOfDiabetes,
  patient_id: patient.patientId,
  dka_at_diagnosis: patient.dkaAtDiagnosis,
  previous_severe_dka: patient.previousSevereDka,
  previous_severe_hypoglycaemia: patient.previousSevereHypoglycaemia,
  school: patient.school,
  current_grade: patient.currentGrade,
  school_plan_complete: patient.schoolPlanComplete,
  school_plan_revision_due: patient.schoolPlanRevisionDue
    ? dayjs(patient.schoolPlanRevisionDue).format('YYYY-MM-DD')
    : null,
  university_course: patient.universityCourse,
  work: patient.work,
  partner: patient.partner,
  guardian_1_name: patient.guardian1Name,
  guardian_2_name: patient.guardian2Name,
  general_comments: patient.generalComments,
  private_health_fund: patient.privateHealthFund,
  type_1_diabetes_autoantibodies: patient.type1DiabetesAutoantibodies,
  other_medications: patient.otherMedications,
  referring_doctor_name: patient.referringDoctorName,
  referring_doctor_address: patient.referringDoctorAddress,
  referral_due: patient.referralDue
    ? dayjs(patient.referralDue).format('YYYY-MM-DD')
    : null,
  team_care_arrangement_due: patient.teamCareArrangementDue
    ? dayjs(patient.teamCareArrangementDue).format('YYYY-MM-DD')
    : null,
});

const getPatients = (page: number, { order, orderBy }: SortParams) =>
  axiosService
    .getSecuredInstance()
    .get('/patients', {
      params: { page, [`q[s]`]: `${camelToSnakeCase(orderBy)} ${order}` },
    })
    .then((response) => {
      const { data, meta } = response.data as {
        data: unknown[];
        meta: Record<string, number>;
      };

      return {
        patients: data.map((item) =>
          transformPatientResponse(item as Record<string, string | number>)
        ),
        meta: { total: meta.total_count || 0 },
      };
    });

const searchPatients = (searchQuery: string) =>
  axiosService
    .getSecuredInstance()
    .get('/patients', {
      params: {
        [`q[first_name_or_last_name_or_patient_id_cont]`]: searchQuery,
        [`q[s]`]: `consultationDate desc`,
      },
    })
    .then((response) => {
      const { data } = response.data as {
        data: unknown[];
      };

      return {
        patients: data.map((item) =>
          transformPatientResponse(item as Record<string, string | number>)
        ),
      };
    });

const getPatient = (id: number): Promise<IPatient> =>
  axiosService
    .getSecuredInstance()
    .get(`/patients/${id}`)
    .then((response) => {
      const { data } = response.data as {
        data: unknown;
      };

      return transformPatientResponse(data as Record<string, string | number>);
    });

const deletePatient = async (id: number): Promise<void> => {
  await axiosService.getSecuredInstance().delete(`/patients/${id}`);
};

const updatePatient = (patient: IPatient): Promise<IPatient> =>
  axiosService
    .getSecuredInstance()
    .put(`/patients/${patient.id}`, {
      patient: transformPatientRequest(patient),
    })
    .then((response) =>
      transformPatientResponse(response.data as Record<string, string | number>)
    );

const createPatient = (patient: Omit<IPatient, 'id'>): Promise<IPatient> =>
  axiosService
    .getSecuredInstance()
    .post('/patients', { patient: transformPatientRequest(patient) })
    .then((response) => {
      const { patient } = response.data as {
        patient: Record<string, string | number>;
      };

      return transformPatientResponse(patient);
    });

const exportSummaryPdf = async (id: number) => {
  const {
    data: { pdf_token },
  } = await axiosService
    .getSecuredInstance()
    .get(`/patients/${id}`)
    .then((response) => response.data as { data: { pdf_token: string } });

  return axiosService
    .getSecuredInstance()
    .get(`/patients/${id}/summary.pdf`, {
      params: { token: pdf_token },
      responseType: 'blob',
    })
    .then((response) => response.data as Blob);
};

export const patientService: IPatientService = Object.freeze({
  getPatients,
  searchPatients,
  get: getPatient,
  delete: deletePatient,
  update: updatePatient,
  create: createPatient,
  exportSummaryPdf,
});

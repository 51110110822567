import { ValidationError } from 'yup';

export const yupValidationErrorToObject = (
  error: ValidationError
): Record<string, string> =>
  error.inner.reduce(
    (acc, { path, errors }) =>
      path ? { ...acc, [path]: errors.join(',') } : acc,
    {}
  );

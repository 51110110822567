export const calculateBMI = (height: number, weight: number): string => {
  const bmi = weight / Math.pow(height / 100, 2);

  return bmi.toFixed(2);
};

export const getBMICategory = (bmi: number): string => {
  if (bmi < 18.5) {
    return 'Underweight';
  } else if (bmi >= 18.5 && bmi <= 24.9) {
    return 'Normal';
  } else if (bmi >= 25 && bmi <= 29.9) {
    return 'Overweight';
  } else {
    return 'Obese';
  }
};

export const getBMI = (height: number, weight: number): string => {
  if (isNaN(height) || isNaN(weight)) {
    return '';
  }

  const bmi = calculateBMI(height, weight);

  return `${bmi} ${getBMICategory(parseFloat(bmi))}`;
};

export const calculateBolusInsulinDose = (
  totalDailyInsulinDose?: number,
  totalBasalInsulinDose?: number
): number | undefined => {
  if (totalDailyInsulinDose && totalBasalInsulinDose) {
    if (isNaN(totalDailyInsulinDose) || isNaN(totalBasalInsulinDose)) {
      return;
    }
    return +totalDailyInsulinDose - +totalBasalInsulinDose;
  }
  return;
};

export const calculateDailyInsulinDose = (
  totalBasalInsulinDose?: number,
  totalBolusInsulinDose?: number
): number | undefined => {
  if (totalBasalInsulinDose && totalBolusInsulinDose) {
    if (isNaN(totalBasalInsulinDose) || isNaN(totalBolusInsulinDose)) {
      return;
    }
    return +totalBasalInsulinDose + +totalBolusInsulinDose;
  }
  return;
};

export const calculateDailyInsulinDosePerKg = (
  totalDailyInsulinDose?: number,
  weight?: number
): string => {
  if (totalDailyInsulinDose && weight) {
    return (totalDailyInsulinDose / weight).toFixed(2);
  }

  return '00';
};

export const calculateBasalInsulinDosePerKg = (
  totalBasalInsulinDose?: number,
  weight?: number
): string => {
  if (totalBasalInsulinDose && weight) {
    return (totalBasalInsulinDose / weight).toFixed(2);
  }

  return '00';
};

export const calculateBolusInsulinDosePerKg = (
  totalDailyInsulinDose?: number,
  totalBasalInsulinDose?: number,
  weight?: number
): string => {
  const totalBolusInsulinDose = calculateBolusInsulinDose(totalDailyInsulinDose, totalBasalInsulinDose);
  if (totalBolusInsulinDose && weight) {
    return (
      totalBolusInsulinDose /
      weight
    ).toFixed(2);
  }

  return '00';
};

import { SxProps, Theme } from '@mui/system';

export const dosageActionStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const dosageSectionStyles: SxProps<Theme> = {
  paddingBottom: 7,
};

export const sectionHeadingStyles: SxProps<Theme> = {
  h2: {
    paddingBottom: 2,
  },
  paddingBottom: 4,
};

import { SxProps } from '@mui/system';

export const timeColumnStyles: SxProps = {
  width: 120,
  display: 'flex',
  alignItems: 'center',
};

export const injectionSettingItemStyles: SxProps = {
  '&:not(:last-child)': {
    paddingBottom: 3,
  },
};

export const injectionSettingLabelStyles: SxProps = {
  fontSize: '0.75rem',
  fontWeight: 700,
};

export const injectionSettingHeaderStyles: SxProps = {
  paddingBottom: 1,
  fontWeight: 700,
};

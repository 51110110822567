import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from '../LoadingScreen';
import { RoutesManagerDashboard } from './RoutesManagerDashboard';
import { RoutesManagerPublic } from './RoutesManagerPublic';

export function RoutesManager(): JSX.Element {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard" component={RoutesManagerDashboard} />
          <Route path="/" component={RoutesManagerPublic} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

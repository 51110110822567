import { useField } from 'formik';
import { useMemo } from 'react';
import { DefaultSelect, DefaultSelectProps } from './DefaultSelect';

type FormikSelectProps = DefaultSelectProps & {
  name: string;
  helperText?: string;
};

export function FormikSelect({
  name,
  helperText = '',
  ...rest
}: FormikSelectProps): JSX.Element {
  const [field, meta] = useField({ name });
  const { touched, error } = meta;
  const shouldShowError = !!touched && !!error;

  const renderedSelect = useMemo(
    () => (
      <DefaultSelect
        {...field}
        {...rest}
        value={field.value === undefined ? '' : (typeof field.value === 'number' ? field.value.toString() : field.value)}
        name={name}
        error={shouldShowError}
        helperText={shouldShowError ? error : helperText}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shouldShowError,
      error,
      name,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(rest),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(field),
    ]
  );

  return renderedSelect;
}

import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import {
  matchPath,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import { DefaultButton } from 'src/components/DefaultButton';
import { useExportPDF, useSinglePatient } from 'src/hooks/PatientHook';
import { ReactComponent as SummaryIcon } from 'src/assets/svgs/bar-chart.svg';
import {
  pageHeadingSummaryButtonStyles,
  singlePatientTabStyles,
  singlePatientsTabContentStyles,
  singlePatientsTabPanelStyles,
} from './styles';
import { useCallback, useMemo } from 'react';
import { PrivateRoute } from 'src/components/PrivateRoute';
import { SinglePatientPageConsultation } from './SinglePatientPageConsultations';
import { SinglePatientPageDetails } from './SinglePatientPageDetails';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { SinglePatientPageInsulinDosage } from './SinglePatientPageInsulinDosage';
import { PageHeading } from 'src/components/PageHeading';

const singlePatientSubPages = [
  {
    label: 'Patient Details',
    url: '',
  },
  {
    label: 'Consultation History',
    url: '/consultation',
  },
  {
    label: 'Insulin Dosage',
    url: '/insulin-dosage',
  },
];

export function SinglePatientPage(): JSX.Element {
  const { patientId } = useParams<{ patientId: string }>();
  const [patient] = useSinglePatient({ patientId, fetchImmediately: true });
  const [exportPdf] = useExportPDF();
  const fullName = `${patient?.firstName} ${patient?.lastName}`;
  const history = useHistory();

  const handleChangeTab = useCallback(
    (_, value: string) => {
      history.push(value);
    },
    [history]
  );

  const handleExport = useCallback(async (): Promise<void> => {
    try {
      await exportPdf(patientId);
    } finally {
    }
  }, [exportPdf, patientId]);

  const { path: parentPath, url: currentParentUrl } = useRouteMatch();
  const { pathname: currentPathname } = useLocation();

  const currentTab = useMemo(() => {
    const matchedTab =
      singlePatientSubPages.find(({ url }) =>
        matchPath(currentPathname, { exact: true, path: `${parentPath}${url}` })
      ) || singlePatientSubPages[0];

    return `${currentParentUrl}${matchedTab.url}`;
  }, [currentPathname, parentPath, currentParentUrl]);

  const breadcrumbs = (
    <Breadcrumbs
      links={[
        { label: 'Patients', url: '/dashboard' },
        { label: fullName || '' },
      ]}
    />
  );

  const actions = (
    <>
      <DefaultButton
        onClick={handleExport}
        sx={pageHeadingSummaryButtonStyles}
        variant="outlined"
      >
        <span>Patient Summary</span> <SummaryIcon />
      </DefaultButton>
      <DefaultButton to={`${currentParentUrl}/new-consultation`}>
        New Consultation
      </DefaultButton>
    </>
  );

  return (
    <>
      <PageHeading
        title={fullName}
        subheading={breadcrumbs}
        actions={actions}
      />
      <Box>
        <Box sx={singlePatientsTabPanelStyles}>
          <Tabs onChange={handleChangeTab} value={currentTab}>
            {singlePatientSubPages.map(({ label, url }) => (
              <Tab
                sx={singlePatientTabStyles}
                label={label}
                key={label}
                value={`${currentParentUrl}${url}`}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={singlePatientsTabContentStyles}>
          <Switch>
            <PrivateRoute exact path={parentPath}>
              <>{patient && <SinglePatientPageDetails patient={patient} />}</>
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`${parentPath}/consultation`}
              component={SinglePatientPageConsultation}
            />
            <PrivateRoute
              path={`${parentPath}/insulin-dosage`}
              component={SinglePatientPageInsulinDosage}
            />
          </Switch>
        </Box>
      </Box>
    </>
  );
}

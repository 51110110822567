export const genderOptions = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const diabetesTypeOptions = [
  {
    value: 'prediabetes',
    label: 'Prediabetes',
  },
  {
    value: 'type_1_diabetes',
    label: 'Type 1 diabetes',
  },
  {
    value: 'type_2_diabetes',
    label: 'Type 2 diabetes',
  },
  {
    value: 'gestational_diabetes',
    label: 'Gestational diabetes',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const type1DiabetesAutoantibodiesOptions = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "gad_65",
    label: "GAD-65",
  },
  {
    value: "iaa",
    label: "IAA",
  },
  {
    value: "ia2",
    label: "IA2",
  },
  {
    value: "znt8",
    label: "ZnT8",
  },
]

export const yesNoOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const yesNoNAOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'not_applicable',
    label: 'N/A',
  },
];

export const dkaOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'mild',
    label: 'Mild pH<7.3',
  },
  {
    value: 'moderate',
    label: 'Moderate pH<7.2',
  },
  {
    value: 'severe',
    label: 'Severe pH<7.1',
  },
];

export const gradeOptions = [
  {
    value: 'kinder',
    label: 'Kinder',
  },
  {
    value: 'prep',
    label: 'Prep',
  },
  {
    value: 'year_1',
    label: 'Year 1',
  },
  {
    value: 'year_2',
    label: 'Year 2',
  },
  {
    value: 'year_3',
    label: 'Year 3',
  },
  {
    value: 'year_4',
    label: 'Year 4',
  },
  {
    value: 'year_5',
    label: 'Year 5',
  },
  {
    value: 'year_6',
    label: 'Year 6',
  },
  {
    value: 'year_7',
    label: 'Year 7',
  },
  {
    value: 'year_8',
    label: 'Year 8',
  },
  {
    value: 'year_9',
    label: 'Year 9',
  },
  {
    value: 'year_10',
    label: 'Year 10',
  },
  {
    value: 'year_11',
    label: 'Year 11',
  },
  {
    value: 'year_12',
    label: 'Year 12',
  },
];

export const medicalTeamOptions = [
  {
    value: 'doctor',
    label: 'Doctor',
  },
  {
    value: 'diabetes_educator',
    label: 'Diabetes Educator',
  },
  {
    value: 'social_worker',
    label: 'Social Worker',
  },
  {
    value: 'dietitian',
    label: 'Dietitian',
  },
  {
    value: 'mental_health_professional',
    label: 'Mental Health Professional',
  },
  {
    value: 'exercise_physiologist',
    label: 'Exercise Physiologist',
  },
  {
    value: 'podiatrist',
    label: 'Podiatrist',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const otherAttendeesOptions = [
  {
    value: 'patient',
    label: 'Patient',
  },
  {
    value: 'mother',
    label: 'Mother',
  },
  {
    value: 'father',
    label: 'Father',
  },
  {
    value: 'other_family_member',
    label: 'Other family member',
  },
  {
    value: 'guardian',
    label: 'Guardian',
  },
  {
    value: 'sibling',
    label: 'Sibling',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const insulinDeliveryOptions = [
  {
    value: 'bd',
    label: 'BD',
  },
  {
    value: 'mdi',
    label: 'MDI',
  },
  {
    value: 'insulin_pump',
    label: 'Insulin pump',
  },
];

export const insulinPumpTypeOptions = [
  {
    value: 'medtronic_640',
    label: 'Medtronic 640G',
  },
  {
    value: 'medtronic_670',
    label: 'Medtronic 670G',
  },
  {
    value: 'medtronic_770',
    label: 'Medtronic 770G',
  },
  {
    value: 'medtronic_780',
    label: 'Medtronic 780G',
  },
  {
    value: 'tandem_t_slim',
    label: 'Tandem T Slim',
  },
  {
    value: 'ypso',
    label: 'Ypso',
  },
  {
    value: 'roche_spirit',
    label: 'Roche Spirit',
  },
  {
    value: 'roche_combo',
    label: 'Roche Combo',
  },
  {
    value: 'omnipod',
    label: 'Omnipod',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const cannulaTypeOptions = [
  {
    value: 'metal',
    label: 'Metal',
  },
  {
    value: 'teflon',
    label: 'Teflon',
  },
];

export const pumpSystemUsedOptions = [
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'medtronic_predictive_low',
    label: 'Medtronic Predictive Low',
  },
  {
    value: 'medtronic_hcl',
    label: 'Medtronic HCL',
  },
  {
    value: 'medtronic_advanced_closed_loop',
    label: 'Medtronic Advanced Closed Loop',
  },
  {
    value: 'tandem_control_iq',
    label: 'Tandem Control IQ',
  },
  {
    value: 'tandem_basal_iq',
    label: 'Tandem Basal IQ',
  },
  {
    value: 'open_aps',
    label: 'Open APS',
  },
  {
    value: 'ypso_cam_aps_fx',
    label: 'Ypso CAM APS FX',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const bolusInsulinTypeOptions = [
  {
    value: 'fiasp',
    label: 'FiAsp',
  },
  {
    value: 'novorapid',
    label: 'NovoRapid',
  },
  {
    value: 'humalog',
    label: 'Humalog',
  },
  {
    value: 'apidra',
    label: 'Apidra',
  },
  {
    value: 'actrapid',
    label: 'Actrapid',
  },
  {
    value: 'humlin_r',
    label: 'Humlin R',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const basalInsulinTypeOptions = [
  {
    value: 'levemir',
    label: 'Levemir',
  },
  {
    value: 'optisulin',
    label: 'Optisulin',
  },
  {
    value: 'toujeo',
    label: 'Toujeo',
  },
  {
    value: 'semglee',
    label: 'Semglee',
  },
  {
    value: 'protaphane',
    label: 'Protaphane',
  },
  {
    value: 'humulin_nph',
    label: 'Humulin NPH',
  },
  {
    value: 'ryzodeg',
    label: 'Ryzodeg',
  },
  {
    value: 'same_as_bolus_insulin_pump',
    label: 'Same as bolus insulin (pump)',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const lineChangeOptions = [...Array(7)].map((_, index) => ({
  value: `${index + 1}`,
  label: `${index + 1}`,
}));

export const cgmUsageOptions = [
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'yes_greater_than_90',
    label: 'Yes (> 90%)',
  },
  {
    value: 'yes_less_than_90',
    label: 'Yes (< 90%)',
  },
];

export const typeOfCgmOptions = [
  {
    value: 'medtronic_g2',
    label: 'Medtronic G2',
  },
  {
    value: 'g3_link',
    label: 'G3 Link',
  },
  {
    value: 'g3_connect',
    label: 'G3 Connect',
  },
  {
    value: 'dexcom_g5',
    label: 'Dexcom G5',
  },
  {
    value: 'dexcom_g6',
    label: 'Dexcom G6',
  },
  {
    value: 'freestyle_libre',
    label: 'Freestyle Libre',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const nephropathyOptions = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'abnormal',
    label: 'Abnormal',
  },
  {
    value: 'previous_microalbumin',
    label: 'Previous microalbumin',
  },
  {
    value: 'on_treatment',
    label: 'On treatment',
  },
  {
    value: 'not_done',
    label: 'Not done',
  },
];

export const thyroidOptions = [
  {
    value: 'normal_antibody_negative',
    label: 'Thyroid function normal- antibody negative',
  },
  {
    value: 'normal_antibody_positive',
    label: 'Thyroid function normal- antibody positive',
  },
  {
    value: 'hypothyroidism',
    label: 'Hypothyroidism',
  },
  {
    value: 'hyperthyroidism',
    label: 'Hyperthyroidism',
  },
  {
    value: 'on_treatment',
    label: 'On treatment',
  },
  {
    value: 'not_done',
    label: 'Not done',
  },
];

export const coeliacOptions = [
  {
    value: 'coeliac_diagnosis_confirmed',
    label: 'Coeliac diagnosis confirmed',
  },
  {
    value: 'antibody_positive',
    label: 'Antibody positive',
  },
  {
    value: 'antibody_negative',
    label: 'Antibody negative',
  },
  {
    value: 'not_done',
    label: 'Not done',
  },
];

export const normalcyOptions = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'abnormal',
    label: 'Abnormal',
  },
  {
    value: 'not_done',
    label: 'Not done',
  },
];

export const supportOptions = [
  {
    value: 'unsatisfactory',
    label: 'Unsatisfactory',
  },
  {
    value: 'sufficient',
    label: 'Sufficient',
  },
  {
    value: 'very_good',
    label: 'Very Good',
  },
  {
    value: 'excellent',
    label: 'Excellent',
  },
];

export const severeOptions = [
  {
    value: '0',
    label: 'No',
  },
  ...[...Array(9)].map((_, index) => ({
    value: `${index + 1}`,
    label: `${index + 1}`,
  })),
];

export const siteOptions = [
  {
    value: 'healthy',
    label: 'Healthy',
  },
  {
    value: 'contact_dermatitis',
    label: 'Contact dermatitis',
  },
  {
    value: 'infection',
    label: 'Infection',
  },
  {
    value: 'lipohypertrophy',
    label: 'Lipohypertrophy',
  },
  {
    value: 'lipoatrophy',
    label: 'Lipoatrophy',
  },
];

export const glycaemicPatternsOptions = [
  {
    value: 'stable',
    label: 'Stable',
  },
  {
    value: 'late_food_bolus',
    label: 'Late food bolus',
  },
  {
    value: 'missed_food_bolus',
    label: 'Missed food bolus',
  },
  {
    value: 'high_bg_after_food',
    label: 'High BG after food',
  },
  {
    value: 'low_bg_after_food',
    label: 'Low BG after food',
  },
  {
    value: 'high_bg_during_after_exercise',
    label: 'High BG during / after exercise',
  },
  {
    value: 'low_bg_during_after_exercise',
    label: 'Low BG during / after exercise',
  },
  {
    value: 'high_after_hypo_treatment',
    label: 'High after hypo treatment',
  },
  {
    value: 'overnight_high',
    label: 'Overnight high',
  },
  {
    value: 'overnight_low',
    label: 'Overnight low',
  },
  {
    value: 'not_in_blood_glucose_target_during_school_hours',
    label: 'Not in blood glucose target during school hours',
  },
  {
    value: 'not_in_blood_glucose_target_out_of_school_hours',
    label: 'Not in blood glucose target out of school hours',
  },
  {
    value: 'carbohydrate_accuracy_questionable',
    label: 'Carbohydrate accuracy questionable',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const managementAdviceOptions = [
  {
    value: 'no_change_required',
    label: 'No change required',
  },
  {
    value: 'bolus_insulin_all_foods',
    label: 'Bolus insulin for all foods',
  },
  {
    value: 'pre_bolus_insulin_if_possible',
    label: 'Pre-bolus insulin if possible',
  },
  {
    value: 'avoid_hypo_overtreatment',
    label: 'Avoid hypo overtreatment',
  },
  {
    value: 'strategies_for_late_bolus',
    label: 'Strategies for late bolus',
  },
  {
    value: 'account_for_fat_and_protein',
    label: 'Account for fat and protein',
  },
  {
    value: 'avoid_rage_impatient_correction_bolus',
    label: 'Avoid rage/impatient correction bolus',
  },
  {
    value: 'settings_adjustment_made',
    label: 'Settings adjustment made',
  },
  {
    value: 'ensure_site_rotation',
    label: 'Ensure site rotation',
  },
  {
    value: 'account_for_lower_insulin_requirement_during_exercise',
    label: 'Account for lower insulin requirement during exercise',
  },
  {
    value: 'account_for_lower_insulin_requirement_overnight_post_exercise',
    label: 'Account for lower insulin requirement overnight post exercise',
  },
  {
    value: 'attention_to_diabetes_management_during_school_work_hours',
    label: 'Attention to diabetes management during school/work hours',
  },
  {
    value: 'attention_to_diabetes_management_out_of_school_work_hours',
    label: 'Attention to diabetes management out of school/work hours',
  },
  {
    value: 'check_carbohydrate_accuracy',
    label: 'Check carbohydrate accuracy',
  },
  {
    value: 'specific_strategies',
    label: 'Specific strategies',
  },
];

export const homeSupportOptions = [
  {
    value: 'parents_working_well_together',
    label: 'Parents working well together',
  },
  {
    value: 'needs_active_parental_involvement',
    label: 'Needs active parental involvement',
  },
  {
    value: 'single_parent_good_support',
    label: 'Single parent good support',
  },
  {
    value: 'single_parent_needs_support',
    label: 'Single parent needs support',
  },
  {
    value: 'self_managing_well',
    label: 'Self-managing well',
  },
  {
    value: 'self_managing_needs_support',
    label: 'Self-managing needs support',
  },
  {
    value: 'other',
    label: 'Other (text)',
  },
];

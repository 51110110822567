import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { SinglePatientForm } from 'src/components/forms/SinglePatientForm';
import { useCreatePatient } from 'src/hooks/PatientHook';
import { IPatient } from 'src/types/Patient';
import {
  pageHeadingStyles,
  tabContentStyles,
  tabPanelStyles,
  tabStyles,
} from './styles';

export function NewPatientPage(): JSX.Element {
  const { url } = useRouteMatch();
  const [createPatient, updateState] = useCreatePatient();
  const history = useHistory();

  const handleCreatePatient = useCallback(
    async (patientData: Omit<IPatient, 'id'>): Promise<void> => {
      const { id } = (await createPatient(patientData)) || {};

      if (id) {
        history.push(`/dashboard/patients/${id}`);
      }
    },
    [history, createPatient]
  );

  return (
    <>
      <Box sx={pageHeadingStyles}>
        <Breadcrumbs
          links={[
            { label: 'Patients', url: '/dashboard' },
            { label: 'New patient' },
          ]}
        />
        <Typography variant="h1">New patient</Typography>
      </Box>
      <Box>
        <Box sx={tabPanelStyles}>
          <Tabs value={url}>
            <Tab sx={tabStyles} label="Patient Details" value={url} />
          </Tabs>
        </Box>
        <Box sx={tabContentStyles}>
          <SinglePatientForm
            onSubmit={handleCreatePatient}
            isLoading={updateState.isLoading}
            errors={updateState.errors}
          />
        </Box>
      </Box>
    </>
  );
}

import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { object, string } from 'yup';
import { FormComponentProps } from 'src/types/FormComponent';
import { FormikTextField } from 'src/components/FormikTextField';
import { Grid, Typography } from '@mui/material';
import { FormikSelect } from 'src/components/FormikSelect';
import { fieldsRowStyles, sectionTitleStyles } from './styles';
import {
  diabetesTypeOptions,
  dkaOptions,
  genderOptions,
  gradeOptions,
  type1DiabetesAutoantibodiesOptions,
  yesNoOptions,
  yesNoNAOptions,
} from 'src/utils/options';
import { FormikDatePicker } from 'src/components/FormikDatePicker';
import { IPatient } from 'src/types/Patient';
import { DefaultButton } from 'src/components/DefaultButton';
import dayjs from 'dayjs';

export type SinglePatientFormData = Omit<IPatient, 'id'>;

export const validationSchema = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  email: string()
    .email('Invalid email address')
    .max(200, 'Email must not exceed 200 characters')
    .nullable(),
  work: string().max(100, 'Field must not exceed 100 characters').nullable(),
  partner: string().max(100, 'Field must not exceed 100 characters').nullable(),
  guardian1Name: string().max(100, 'Field must not exceed 100 characters').nullable(),
  guardian2Name: string().max(100, 'Work must not exceed 100 characters').nullable(),
});

type SinglePatientFormProps = FormComponentProps<SinglePatientFormData> & {
  onDelete?: () => Promise<void> | void;
  isLoading?: boolean;
  errors?: Record<string, string> | undefined;
};

export function SinglePatientForm({
  onSubmit,
  onDelete,
  defaultValues = {},
  isLoading,
  errors,
}: SinglePatientFormProps): JSX.Element {
  const initialValues: SinglePatientFormData = useMemo(() => {
    const {
      firstName = '',
      lastName = '',
      email = '',
      patientId = '',
      gender = '',
      dateOfBirth = null,
      dateOfDiagnosis = null,
      typeOfDiabetes = '',
      type1DiabetesAutoantibodies = [],
      dkaAtDiagnosis = '',
      previousSevereHypoglycaemia = '',
      previousSevereDka = '',
      school = '',
      currentGrade = '',
      universityCourse = '',
      work = '',
      guardian1Name = '',
      guardian2Name = '',
      schoolPlanComplete = '',
      schoolPlanRevisionDue = null,
      partner = '',
      generalComments = '',
      privateHealthFund = '',
      referringDoctorName = '',
      referringDoctorAddress = '',
      otherMedications = '',
      referralDue = null,
      teamCareArrangementDue = null,
    } = defaultValues;

    return {
      firstName,
      lastName,
      email,
      patientId,
      dateOfBirth,
      dateOfDiagnosis,
      gender,
      typeOfDiabetes,
      type1DiabetesAutoantibodies,
      dkaAtDiagnosis,
      previousSevereHypoglycaemia,
      previousSevereDka,
      school,
      currentGrade,
      schoolPlanComplete,
      schoolPlanRevisionDue,
      universityCourse,
      work,
      partner,
      guardian1Name,
      guardian2Name,
      generalComments,
      privateHealthFund,
      referringDoctorName,
      referringDoctorAddress,
      otherMedications,
      referralDue,
      teamCareArrangementDue,
    };
  }, [defaultValues]);

  const today = useMemo(() => dayjs(), []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialErrors={errors}
      initialTouched={errors}
    >
      <Form>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={3}>
            <FormikTextField label="First name" name="firstName" />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField label="Last name" name="lastName" />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField label="Patient ID" name="patientId" />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField label="Email" name="email" />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={3}>
            <FormikSelect
              label="Gender"
              name="gender"
              options={genderOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <FormikDatePicker
              maxDate={today}
              name="dateOfBirth"
              label="Date of birth"
            />
          </Grid>
          <Grid item xs={3}>
            <FormikDatePicker
              maxDate={today}
              name="dateOfDiagnosis"
              label="Date of diagnosis"
            />
          </Grid>
          <Grid item xs={3}>
            <FormikSelect
              label="Type of diabetes"
              name="typeOfDiabetes"
              options={diabetesTypeOptions}
            />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={6}>
            <FormikSelect
              label="DKA at diagnosis"
              name="dkaAtDiagnosis"
              options={dkaOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikSelect
              label="Previous severe hypoglycaemia"
              name="previousSevereHypoglycaemia"
              options={yesNoOptions}
            />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={6}>
            <FormikSelect
              label="Type 1 diabetes autoantibodies"
              name="type1DiabetesAutoantibodies"
              options={type1DiabetesAutoantibodiesOptions}
              multiple
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField label="Other medications" name="otherMedications" />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={6}>
            <FormikSelect
              label="Previous severe DKA"
              name="previousSevereDka"
              options={yesNoOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField label="Current school/Pre-school" name="school" />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={3}>
            <FormikSelect
              label="Current grade"
              name="currentGrade"
              options={gradeOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <FormikSelect
              label="School plan complete"
              name="schoolPlanComplete"
              options={yesNoNAOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <FormikDatePicker
              label="School plan revision due"
              name="schoolPlanRevisionDue"
            />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField
              label="University course"
              name="universityCourse"
            />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={3}>
            <FormikTextField label="Work" name="work" />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField label="Partner" name="partner" />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField
              label="Parent 1/Guardian name"
              name="guardian1Name"
            />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField
              label="Parent 2/Guardian name"
              name="guardian2Name"
            />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={6}>
            <FormikTextField
              label="Referring doctor name"
              name="referringDoctorName"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              label="Referring doctor addess"
              name="referringDoctorAddress"
            />
          </Grid>
        </Grid>
        <Grid sx={fieldsRowStyles} container spacing={2}>
          <Grid item xs={3}>
            <FormikDatePicker
              name="referralDue"
              label="Referral due date"
            />
          </Grid>
          <Grid item xs={3}>
            <FormikDatePicker
              name="teamCareArrangementDue"
              label="Team care arrangement due date"
            />
          </Grid>
          <Grid item xs={3}>
            <FormikTextField label="Private heath fund" name="privateHealthFund" />
          </Grid>
        </Grid>
        <Grid container sx={fieldsRowStyles}>
          <Grid item xs={12}>
            <Typography sx={sectionTitleStyles} variant="h2">
              General comments
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormikTextField name="generalComments" multiline rows={4} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            {onDelete && (
              <DefaultButton
                type="button"
                variant="outlined"
                color="error"
                onClick={onDelete}
              >
                Delete
              </DefaultButton>
            )}
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <DefaultButton isLoading={isLoading} type="submit" color="primary">
              Submit
            </DefaultButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

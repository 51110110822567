import { useCallback, useEffect } from 'react';
import { useStores } from 'src/stores';
import { AsyncState } from 'src/types/Hook';
import { useAsyncState } from './AsyncStateHook';

export const useHandleReconfirmAccount = (
  confirmationToken: string | null
): AsyncState => {
  const { authStore } = useStores();
  const { start, finish, state } = useAsyncState();

  const reconfirmAccount = useCallback(async () => {
    if (!confirmationToken) {
      return;
    }

    start();

    try {
      await authStore.reconfirmAccount(confirmationToken);
      finish();
    } catch (error) {
      finish({ error: (error as Error).message });
    }
  }, [authStore, finish, start, confirmationToken]);

  useEffect(() => {
    reconfirmAccount();
  }, [reconfirmAccount]);

  return state;
};

import { useEffect, useState } from 'react';

const getResponsiveImages = (imageName: string) => {
  const [extension, ...nameParts] = imageName.split('.').reverse();
  const name = nameParts.join('');

  return {
    '960w': imageName,
    '1440w': `${name}@2x.${extension}`,
    '1920w': `${name}@3x.${extension}`,
  };
};

const loadImages = async (imagesToLoad: Record<string, string>) => {
  const results = await Promise.all(
    Object.keys(imagesToLoad).map(
      (key) => import(`../../assets/images/${imagesToLoad[key]}`)
    )
  );

  return new Promise<Record<string, unknown>[]>((resolve) =>
    setTimeout(() => resolve(results), 0)
  );
};

/**
 * Hook to load responsive image and return a srcSet string
 * Images will be loaded from src/assets/images
 *
 * @param imageName
 * @param images
 */
export const useResponsiveImage = (
  imageName?: string,
  images?: Record<string, string>
): [string, { isLoading: boolean; error: string }] => {
  const [srcSet, setSrcSet] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  // Effect to load image
  useEffect(() => {
    if (!imageName && !images) {
      setError(
        'useResponsiveImage hook: please provide image name or image set'
      );

      setIsLoading(false);

      return;
    }

    const handleLoadImage = async () => {
      const imagesToLoad =
        images || (imageName ? getResponsiveImages(imageName) : {});

      try {
        const results = await loadImages(imagesToLoad);

        const sizes = Object.keys(imagesToLoad);
        const srcSet = results
          .map((val, index) => `${val.default} ${sizes[index]}`)
          .join(', ');
        setSrcSet(srcSet);
      } catch (error) {
        setError(error as string);
      } finally {
        setIsLoading(false);
      }
    };

    handleLoadImage();
  }, [imageName, images]);

  return [srcSet, { isLoading, error }];
};

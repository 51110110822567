import { Box } from '@mui/system';
import React from 'react';
import { DashboardLayoutFooter } from './DashboardLayoutFooter';
import { DashboardLayoutHeader } from './DashboardLayoutHeader';
import { dashboardContentStyles, dashboardPageWrapperStyles } from './styles';

type DashboardLayoutProp = {
  children: React.ReactNode;
};

export function DashboardLayout({
  children,
}: DashboardLayoutProp): JSX.Element {
  return (
    <Box>
      <DashboardLayoutHeader />
      <Box sx={dashboardPageWrapperStyles}>
        <Box sx={dashboardContentStyles}>{children}</Box>
        <DashboardLayoutFooter />
      </Box>
    </Box>
  );
}

import { SxProps, Theme } from '@mui/system';

export const dashboardPageWrapperStyles: SxProps<Theme> = {
  paddingTop: '90px',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  boxSizing: 'border-box',
};

export const dashboardContentStyles: SxProps<Theme> = {
  maxWidth: ({ breakpoints }) => breakpoints.values.lg,
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: 4,
  flex: 1,
  width: '100%',
};

export const dashboardLayoutHeaderStyles: SxProps = {
  paddingTop: 2.5,
  paddingBottom: 2.5,
  borderBottom: '0.5px solid #F4F4F4',
  boxShadow: '0px 4px 24px 5px rgba(71, 71, 71, 0.08)',
  backgroundColor: '#fff',
};

export const dashboardLayoutHeaderContentStyles: SxProps<Theme> = {
  paddingLeft: 4,
  paddingRight: 4,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: ({ breakpoints }) => breakpoints.values.lg,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const dashboardLayoutHeaderLeft: SxProps<Theme> = {
  flex: '0 0 auto',
};

export const dashboardLayoutHeaderRight: SxProps<Theme> = {
  display: 'flex',
  flex: '1 1 100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const logoContainerStyles: SxProps<Theme> = {
  a: {
    lineHeight: 1,
    display: 'block',
  },
  svg: {
    maxWidth: '168px',
    height: 'auto',
  },
};

export const dashboardLayoutHeaderMenuStyles: SxProps = {
  borderBottom: '0.5px solid #F4F4F4',
  boxShadow: '0px 4px 24px 5px rgba(71, 71, 71, 0.08)',
  width: 270,
};

export const dashboardLayoutHeaderMenuListStyles: SxProps = {
  paddingTop: 0,
  paddingBottom: 0,
};

export const dashboardLayoutHeaderMenuItemStyles: SxProps<Theme> = {
  padding: 0,
  '&:not(:last-child)': {
    borderBottom: '1px solid',
    borderBottomColor: ({ palette }) => palette.divider,
  },
  a: {
    textDecoration: 'none',
    color: ({ palette }) => palette.text.primary,
    display: 'flex',
    padding: 3,
    flex: 1,
    justifyContent: 'start',
  },
};

export const menuContentWrapperStyles: SxProps = {
  display: 'flex',
  padding: 3,
  flex: 1,
};

export const menuContentIconStyles: SxProps = {
  display: 'flex',
  justifyContent: 'start',
  width: '24px',
  paddingRight: 2,
  boxSizing: 'content-box',
};

export const dashboardFooterStyles: SxProps<Theme> = {
  borderTop: '0.5px solid #F4F4F4',
  boxShadow: '0px -4px 24px 5px rgba(71, 71, 71, 0.08)',
  height: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
};

export const dashboardFooterTextStyles: SxProps<Theme> = {
  color: ({ palette }) => palette.charcoal.secondary,
};

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  headingContainerStyles,
  headingRightStyles,
  headingLeftStyles,
} from './styles';

type PageHeadingProp = {
  actions?: React.ReactNode;
  title: string;
  subheading?: React.ReactNode;
};

export function PageHeading({
  actions,
  title,
  subheading,
}: PageHeadingProp): JSX.Element {
  return (
    <Box sx={headingContainerStyles}>
      <Box sx={headingLeftStyles}>
        {subheading}
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box sx={headingRightStyles}>{actions}</Box>
    </Box>
  );
}

import { TableCell, TableSortLabel } from '@mui/material';
import { ReactNode } from 'react';

type DefaultTableHeadCellProps = {
  orderBy?: string;
  order?: 'asc' | 'desc';
  label: ReactNode;
  cellId?: string;
  onSort: () => Promise<void>;
};

export function DefaultTableHeadCell({
  label,
  cellId,
  orderBy,
  order,
  onSort,
}: DefaultTableHeadCellProps): JSX.Element {
  return (
    <TableCell>
      <TableSortLabel
        active={orderBy === cellId}
        direction={orderBy === cellId ? order : 'asc'}
        onClick={onSort}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
}

import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSinglePatient } from 'src/hooks/PatientHook';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import {
  ConsultationForm,
  ConsultationFormData,
} from 'src/components/forms/ConsultationForm';
import { PageHeading } from 'src/components/PageHeading';
import {
  useFetchConsultation,
  useUpdatePatientConsultation,
} from 'src/hooks/ConsultationHook';
import { IConsultation } from 'src/types/Consultation';

export const SingleConsultationPage = observer(function (): JSX.Element {
  const { patientId, consultationId } =
    useParams<{ patientId: string; consultationId: string }>();
  const [patient] = useSinglePatient({ patientId, fetchImmediately: true });
  const fullName = `${patient?.firstName} ${patient?.lastName}`;
  const [consultation] = useFetchConsultation({
    fetchImmediately: true,
    patientId,
    consultationId,
  });
  const consultationDate = consultation ? dayjs(consultation?.consultationDate).format('DD/MM/YYYY') : '';

  const [handleUpdateConsultation, updateState] =
    useUpdatePatientConsultation();

  const handleUpdatePatientConsultation = useCallback(
    async (consultation: ConsultationFormData) => {
      if (!patientId || !consultationId) {
        return;
      }

      const parsedPatientId = parseInt(patientId, 10);
      const parsedConsultationId = parseInt(patientId, 10);

      await handleUpdateConsultation(parsedPatientId, {
        id: parsedConsultationId,
        ...consultation,
      } as Omit<IConsultation, 'user'>);
    },
    [patientId, handleUpdateConsultation, consultationId]
  );

  return (
    <div>
      <PageHeading
        title="Consultation"
        subheading={
          <Breadcrumbs
            links={[
              { label: 'Patients', url: '/dashboard' },
              { label: fullName, url: `/dashboard/patients/${patientId}` },
              { label: `Consultation ${consultationDate}` },
            ]}
          />
        }
      />
      <ConsultationForm
        defaultValues={consultation}
        onSubmit={handleUpdatePatientConsultation}
        isLoading={updateState.isLoading}
        errors={updateState.errors}
      />
    </div>
  );
});

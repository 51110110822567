import { MenuItem, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IPatient } from 'src/types/Patient';
import {
  dobTextStyles,
  searchResultItemStyles,
  searchResultItemTextLabelStyles,
  searchResultItemTextStyles,
} from './styles';

type PatientSearchBarResultItemProps = {
  patient: IPatient;
};

export function PatientSearchBarResultItem({
  patient: { id, firstName, lastName, patientId, dateOfBirth },
}: PatientSearchBarResultItemProps): JSX.Element {
  return (
    <MenuItem sx={searchResultItemStyles}>
      <Link to={`/dashboard/patients/${id}`}>
        <Typography sx={searchResultItemTextStyles} fontWeight="700">
          {firstName} {lastName}
        </Typography>
        <Typography sx={searchResultItemTextStyles}>
          <Typography sx={searchResultItemTextLabelStyles} component="span">
            Patient Number
          </Typography>{' '}
          {patientId}
        </Typography>
        <Typography sx={searchResultItemTextStyles}>
          <Typography sx={searchResultItemTextLabelStyles} component="span">
            Date of Birth
          </Typography>{' '}
          <Typography sx={dobTextStyles} component="span">
            {dayjs(dateOfBirth).format('DD/MM/YYYY')}
          </Typography>
        </Typography>
      </Link>
    </MenuItem>
  );
}

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { labelStyles, selectStyles } from './styles';
import Checkbox from '@mui/material/Checkbox';

export type SelectOption<ValueType = string> = {
  label: string;
  value: ValueType;
};

export type DefaultSelectProps = SelectProps & {
  options: SelectOption[];
  helperText?: string;
  error?: boolean;
};

export function DefaultSelect({
  label,
  onChange,
  options,
  helperText,
  error,
  ...rest
}: DefaultSelectProps): JSX.Element {
  return (
    <FormControl error={error} fullWidth>
      <InputLabel htmlFor={rest.id || rest.name} sx={labelStyles}>
        {label}
      </InputLabel>
      <Select
        label={label}
        onChange={onChange}
        sx={selectStyles}
        inputProps={{ id: rest.id || rest.name }}
        value={rest.value}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            return options.filter(({ value }) => selected.includes(value)).map(({ label }) => label).join(', ');
          } else {
            return options.filter(({ value }) => selected === value).map(({ label }) => label);
          }
        }}
        {...rest}
      >
        {
          !rest.multiple &&
          <MenuItem value="">
            <em>(not set)</em>
          </MenuItem>
        }
        {options.map(({ value, label }) => (
          <MenuItem value={value} key={value as string}>
            {
              !!rest.multiple &&
              <Checkbox checked={(rest.value as string[])?.indexOf(value) > -1} />
            }
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

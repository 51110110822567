import axios, { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { transformPatientResponse } from 'src/services/PatientService';
import { useStores } from 'src/stores';
import { AsyncState, FetchDataHook, HandlerHook } from 'src/types/Hook';
import { IPatient } from 'src/types/Patient';
import { SortParams } from 'src/types/SortParams';
import { useAsyncState } from './AsyncStateHook';

export const useFetchPatients: FetchDataHook<
  { patients: IPatient[]; total: number },
  { initialSort: SortParams },
  (page: number, sort: SortParams) => Promise<void>
> = (params) => {
  const { fetchImmediately = true, initialSort } = params || {};
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [total, setTotal] = useState(0);
  const { state, start, finish } = useAsyncState();
  const { patientStore } = useStores();

  const refetch = useCallback(
    async (page: number, sort) => {
      start();
      try {
        const { patients = [], total = 0 } =
          (await patientStore.getPatients(page, sort)) || {};

        setPatients(patients);
        setTotal(total);
        finish();
      } catch (error) {
        finish({
          error: (error as Error)?.message || 'Could not get patient data',
        });
      }
    },
    [patientStore, finish, start]
  );

  const { order: initialOrder, orderBy: initialOrderBy } = initialSort || {};

  useEffect(() => {
    if (!fetchImmediately) {
      return;
    }

    refetch(
      1,
      initialOrder &&
        initialOrderBy && { order: initialOrder, orderBy: initialOrderBy }
    );
  }, [fetchImmediately, refetch, initialOrder, initialOrderBy]);

  return [
    { patients, total },
    { ...state, refetch },
  ];
};

export const useSearchPatients: FetchDataHook<
  IPatient[],
  Record<string, unknown>,
  (searchQuery: string) => Promise<void>,
  { clearResult: () => void }
> = () => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const { state, start, finish } = useAsyncState();
  const { patientStore } = useStores();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const refetch = useCallback(
    async (searchQuery: string) => {
      start();

      try {
        const { patients = [] } =
          (await patientStore.searchPatients(searchQuery)) || {};

        setPatients(patients);
        finish();
      } catch (error) {
        finish({
          error:
            (error as Error)?.message || 'Could not get patient search results',
        });
      }
    },
    [patientStore, finish, start]
  );

  const refetchWrapper = async (searchQuery: string) => {
    setSearchValue(searchQuery);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue) {
        refetch(searchValue);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, refetch]);

  const clearResult = useCallback(() => {
    setPatients([]);
  }, [setPatients]);

  return [patients, { ...state, refetch: refetchWrapper, clearResult }];
};

export const useSinglePatient: FetchDataHook<
  IPatient | undefined,
  { patientId: string },
  (patientId: string) => Promise<void>
> = (params) => {
  const { fetchImmediately = true, patientId } = params || {};
  const { patientStore } = useStores();
  const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;
  const patient = patientStore.state.patients.get(parsedPatientId);
  const { state, start, finish } = useAsyncState();
  const { started } = state;
  const foundPatient = !!patient;

  const refetch = useCallback(async () => {
    if (!parsedPatientId) {
      finish({
        error: 'Invalid patient id',
      });
    }

    try {
      start();
      await patientStore.getPatient(parsedPatientId);

      finish();
    } catch (error) {
      finish({
        error:
          (error as Error)?.message ||
          `Could not get patient with id ${parsedPatientId}`,
      });
    }
  }, [patientStore, parsedPatientId, finish, start]);

  useEffect(() => {
    if (!fetchImmediately || started || foundPatient) {
      return;
    }

    refetch();
  }, [foundPatient, started, fetchImmediately, refetch]);

  return [patient, { ...state, refetch }];
};

export const useUpdatePatient = (): [
  (patient: IPatient) => Promise<void>,
  AsyncState
] => {
  const { state, start, finish } = useAsyncState();
  const { patientStore, alertStore } = useStores();

  const handleSubmit = useCallback(
    async (patient: IPatient) => {
      try {
        start();
        await patientStore.updatePatient(patient);

        alertStore.addAlert({
          content: `Patient ${patient.firstName} ${patient.lastName} has been updated successfully`,
          type: 'success',
        });

        finish();
      } catch (error) {
        const errorMessage = `Could not update patient ${patient.firstName} ${patient.lastName}`;

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        const axiosErrorMessages = checkErrorForDataErrors(error as Error);

        finish({
          error: errorMessage,
          errors: axiosErrorMessages,
        });
      }
    },
    [start, finish, patientStore, alertStore]
  );

  return [handleSubmit, { ...state }];
};

export const useDeletePatient: HandlerHook<
  (patientId: number) => Promise<void>
> = () => {
  const { state, start, finish } = useAsyncState();
  const { patientStore, alertStore } = useStores();

  const handleSubmit = useCallback(
    async (patientId: number) => {
      try {
        start();
        await patientStore.deletePatient(patientId);

        alertStore.addAlert({
          content: 'Patient has been deleted successfully',
          type: 'success',
        });

        finish();
      } catch (error) {
        const errorMessage = `Could not delete patient with id: ${patientId}`;

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        finish({
          error: errorMessage,
        });
      }
    },
    [start, finish, patientStore, alertStore]
  );

  return [handleSubmit, { ...state }];
};

export const useCreatePatient: HandlerHook<
  (patientData: Omit<IPatient, 'id'>) => Promise<IPatient | undefined>
> = () => {
  const { state, start, finish } = useAsyncState();
  const { patientStore, alertStore } = useStores();

  const handleSubmit = useCallback(
    async (patientData: Omit<IPatient, 'id'>) => {
      try {
        start();
        const patient = await patientStore.createPatient(patientData);

        alertStore.addAlert({
          content: `Patient ${patientData.firstName} ${patientData.lastName} has been created successfully`,
          type: 'success',
        });

        finish();

        return patient;
      } catch (error) {
        const errorMessage = `Could not create patient ${patientData.firstName} ${patientData.lastName}`;

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        const axiosErrorMessages = checkErrorForDataErrors(error as Error);

        finish({
          error: errorMessage,
          errors: axiosErrorMessages,
        });
      }
    },
    [start, finish, patientStore, alertStore]
  );

  return [handleSubmit, { ...state }];
};

const downloadPdf = (pdfString: Blob) => {
  const blob = new Blob([pdfString], {type: 'application/pdf'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'patient_summary.pdf';
  document.body.append(link);
  link.click();
  link.remove();
};

export const useExportPDF = (): [
  (patientID: string) => void,
  AsyncState
] => {
  const { patientStore } = useStores();
  const { state, start, finish } = useAsyncState();

  const exportPdf = useCallback(
    async (patientId: string) => {
      const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;

      if (!parsedPatientId) {
        finish({
          error: 'Invalid patient id',
        });
      }

      start();
      const pdfString = await patientStore.patientService.exportSummaryPdf(
        parsedPatientId
      );

      downloadPdf(pdfString);

      finish();
    },
    [patientStore, finish, start]
  );

  return [exportPdf, state];
};

const checkErrorForDataErrors = (error: Error): Record<string, string> | undefined => {
  let axiosErrorMessagesTransformed: Record<string, string> | undefined;
  if (axios.isAxiosError(error) && error.response) {
    const axiosErrorMessages =
      (error as AxiosError<{ errors: Record<string, string[]> }>).response?.data?.errors;

    const joinedErrors: Record<string, string> = {};
    Object.keys(axiosErrorMessages || {}).forEach((key) => {
      const values = (axiosErrorMessages || {})[key];
      joinedErrors[key] = values.join(', ');
    });

    axiosErrorMessagesTransformed = transformPatientResponse(joinedErrors) as Partial<IPatient> as Record<string, string>;
  }

  return axiosErrorMessagesTransformed;
};

import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const SEARCH_CONTAINER_TRANSITION = 500;

export const searchContainer: SxProps<Theme> = {
  width: '340px',
  marginRight: 2,
  marginLeft: 3,
  transition: `all ${SEARCH_CONTAINER_TRANSITION}ms`,
  position: 'relative',
};

export const searchContainerFocused: SxProps = {
  width: '100%',
};

export const searchResultStyles: SxProps = {
  border: '0.5px solid #F4F4F4',
  boxShadow: '0px 0px 16px 2px rgba(71, 71, 71, 0.16)',
  paddingLeft: 0.5,
  paddingRight: 0.5,
  ul: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export const searchResultItemStyles: SxProps<Theme> = {
  padding: 0,
  '&:not(:last-child)': {
    borderBottom: '1px solid',
    borderBottomColor: ({ palette }) => palette.divider,
  },
  a: {
    textDecoration: 'none',
    color: ({ palette }) => palette.text.primary,
    display: 'flex',
    padding: 3,
    flex: 1,
  },
};

export const searchResultItemTextStyles: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  color: ({ palette }) => palette.charcoal.main,
  '&:first-of-type': {
    justifyContent: 'flex-start',
  },
  '&:last-of-type': {
    justifyContent: 'flex-end',
  },
};

export const searchResultItemTextLabelStyles: SxProps<Theme> = {
  paddingRight: 1,
  color: ({ palette }) => palette.charcoal.secondary,
};

export const searchNotificationLabelStyles: SxProps<Theme> = {
  padding: 1,
};

export const dobTextStyles: SxProps<Theme> = {
  minWidth: 90,
};

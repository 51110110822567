import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useStores } from 'src/stores';
import { AuthStore } from 'src/stores/AuthStore';
import { HandlerHook } from 'src/types/Hook';
import { IUpdateUserData } from 'src/types/User';
import { useAsyncState } from './AsyncStateHook';

export const useAuth = (): Pick<AuthStore, 'login' | 'logout'> &
  AuthStore['state'] => {
  const { authStore } = useStores();

  return {
    login: authStore.login,
    logout: authStore.logout,
    user: authStore.state.user,
    authenticationToken: authStore.state.authenticationToken,
  };
};

export const useHandleLogin: HandlerHook<
  (data: { email: string; password: string }) => Promise<void>,
  { onLoginSuccess?: () => void }
> = ({ onLoginSuccess } = {}) => {
  const { authStore } = useStores();
  const { start, finish, state } = useAsyncState();

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      start();

      try {
        await authStore.login(email, password);
        finish();

        if (onLoginSuccess) {
          onLoginSuccess();
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || 'Could not login, please try again';
        finish({ error: errorMessage });
      }
    },
    [authStore, start, finish, onLoginSuccess]
  );

  return [login, state];
};

export const useHandleUpdateUser: HandlerHook<
  (data: IUpdateUserData) => Promise<void>
> = () => {
  const { authStore, alertStore } = useStores();
  const { start, finish, state } = useAsyncState();

  const login = useCallback(
    async (userData: IUpdateUserData) => {
      start();
      const currentEmail = authStore.state.user?.email;

      try {
        await authStore.updateUser(userData);
        finish();

        if (userData.email !== currentEmail) {
          authStore.logout();
          alertStore.addAlert({
            content:
              'You have changed your email, please confirm it before login again',
            type: 'success',
          });
        } else {
          alertStore.addAlert({
            content: 'Account updated successfully',
            type: 'success',
          });
        }
      } catch (error) {
        const axiosErrorMessages =
          (error as AxiosError<{ errors: string[] }>).response?.data?.errors ||
          [];
        const errorMessage =
          axiosErrorMessages.join(', ') ||
          'Could not update user, please try again';

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        finish({ error: errorMessage });
      }
    },
    [authStore, start, finish, alertStore]
  );

  return [login, state];
};

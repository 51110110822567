import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import { SortParams } from 'src/types/SortParams';
import { getOrder } from 'src/utils/table';
import { DefaultTableHeadCell } from './DefaultTableHeadCell';
import { tableContainerStyles, tableHeaderStyles } from './styles';

type HeadCellProps = {
  cellId: string;
  label: string;
};

export type DefaultTableProp = {
  headCells: HeadCellProps[];
  onSort?: (sort: SortParams) => Promise<void>;
  orderBy?: string;
  order?: 'asc' | 'desc';
  children: React.ReactNode;
  paginationProps?: TablePaginationProps;
};

export function DefaultTable({
  onSort,
  order,
  orderBy,
  headCells,
  children,
  paginationProps,
}: DefaultTableProp): JSX.Element {
  const createSortHandler = (cellId: string) => async () => {
    if (!onSort || !order || !orderBy) {
      return;
    }

    onSort({ orderBy: cellId, order: getOrder(cellId, order, orderBy) });
  };

  //TODO: implement DefaultTable
  return (
    <Box sx={tableContainerStyles}>
      <Table>
        <TableHead>
          <TableRow sx={tableHeaderStyles}>
            {headCells.map((cell) => (
              <DefaultTableHeadCell
                key={cell.cellId}
                {...cell}
                order={order}
                orderBy={orderBy}
                onSort={createSortHandler(cell.cellId)}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      {!!paginationProps && (
        <TablePagination component="div" {...paginationProps} />
      )}
    </Box>
  );
}

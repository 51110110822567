import { useCallback, useState } from 'react';

export type DialogState = {
  isOpened: boolean;
};

export const useDialogState = (): [
  state: DialogState,
  open: () => void,
  close: () => void
] => {
  const [isOpened, setIsOpened] = useState(false);

  const open = useCallback(() => {
    setIsOpened(true);
  }, []);

  const close = useCallback(() => {
    setIsOpened(false);
  }, []);

  return [
    {
      isOpened,
    },
    open,
    close,
  ];
};

import { Theme, SxProps } from '@mui/system';

export const defaultButtonStyles: SxProps = {
  paddingTop: 1.5,
  paddingBottom: 1.5,
  minWidth: '170px',
  display: 'flex',
  alignItems: 'center',
};

export const loadingContainerStyles: SxProps = {
  paddingLeft: 1,
  lineHeight: 1,
};

export const linkButtonStyles: SxProps<Theme> = {
  a: {
    color: 'white',
    boxShadow: ({ shadows }) => shadows as Record<number, string>[2],
    backgroundColor: ({ palette }) => palette.primary.main,
    textDecoration: 'none',
    borderRadius: '4px',
    padding: '12px 16px',
    minWidth: '170px',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    textAlign: 'center',
  },
};

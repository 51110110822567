import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent } from 'react';
import { InsulinPumpSettingsInputLabel } from './InsulinPumpSettingsInputLabel';
import {
  InsulinPumpSettingsInputData,
  InsulinPumpSettingsInputSlot,
} from './InsulinPumpSettingsInputSlot';
import { pumpSettingsRowStyles } from './styles';

type InsulinPumpSettingsInputProps = {
  name: string;
  value: Record<string, InsulinPumpSettingsInputData>;
  onChange: (
    event: {
      target: {
        name: string;
        value: Record<string, InsulinPumpSettingsInputData>;
      };
    } & ChangeEvent
  ) => void;
  error?: string;
};

const morningSlots = [
  '0:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
];

const afternoonSlots = [
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export function InsulinPumpSettingsInput({
  value,
  onChange,
  name,
  error,
}: InsulinPumpSettingsInputProps): JSX.Element {
  const handleSlotChange = (setting: InsulinPumpSettingsInputData) => {
    onChange({
      target: { name, value: { ...value, [setting.time]: setting } },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
  };

  return (
    <Box>
      {error && <Typography color="error.main">{error}</Typography>}
      <Grid sx={pumpSettingsRowStyles} spacing={2} wrap="nowrap" container>
        <InsulinPumpSettingsInputLabel />
        {morningSlots.map((slot) => (
          <Grid xs item key={slot}>
            <InsulinPumpSettingsInputSlot
              time={slot}
              value={value && value[slot]}
              onChange={handleSlotChange}
            />
          </Grid>
        ))}
      </Grid>
      <Grid spacing={2} wrap="nowrap" container>
        <InsulinPumpSettingsInputLabel />
        {afternoonSlots.map((slot) => (
          <Grid xs item key={slot}>
            <InsulinPumpSettingsInputSlot
              time={slot}
              key={slot}
              value={value[slot]}
              onChange={handleSlotChange}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

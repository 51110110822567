import dayjs from 'dayjs';
import { IConsultation } from 'src/types/Consultation';
import { IConsultationService } from 'src/types/ConsultationService';
import { SortParams } from 'src/types/SortParams';
import { camelToSnakeCase } from 'src/utils/camelToSnakeCase';
import { axiosService } from './AxiosService';

export const transformConsultationResponse = ({
  id,
  user,
  glycosylated_haemoglobin,
  glycosylated_haemoglobin_mmol,
  consultation_date,
  telemedicine,
  height,
  weight,
  medical_team_attending,
  other_attendees,
  multidisciplinary_case_meeting,
  insulin_delivery,
  insulin_pump_type,
  pump_warranty,
  cannula_type,
  pump_system_used,
  bolus_insulin_type,
  basal_insulin_type,
  total_daily_insulin_dose,
  total_basal_insulin_dose,
  total_bolus_insulin_dose,
  number_of_injections_per_day,
  number_of_blood_glucose_entries_per_day,
  average_amount_of_carbohydrate_entries_per_day,
  average_amount_of_carbohydrates_per_day,
  line_change_interval_completed_days,
  cgm_usage,
  type_of_cgm,
  severe_hypoglycaemia,
  severe_dka,
  variability,
  time_in_range,
  time_below_range,
  time_above_range,
  complication_screen_due_date,
  nephropathy,
  thyroid,
  coeliac,
  cholesterol,
  retinopathy,
  other_conditions,
  blood_pressure_systole,
  blood_pressure_diastole,
  sites,
  settings_adjusted,
  school_support_assessment,
  home_support,
  home_support_other,
  glycaemic_patterns,
  glycaemic_patterns_other,
  management_advice,
  food_bolus_specific_strategies,
  next_consultation,
  consultation_summary,
  sick_day_management_plan_due,
  days_in_hospital_due_to_diabetes_since_last_visit,
}: Record<
  string,
  string | number | string[] | Record<string, string | number>
>): IConsultation => ({
  id: id as number,
  hbA1c: parseFloat(glycosylated_haemoglobin as string),
  hbA1cMmol: parseFloat(glycosylated_haemoglobin_mmol as string),
  consultationDate: consultation_date as string,
  telemedicine: telemedicine as string,
  height: height as number,
  weight: weight as number,
  medicalTeamAttending: medical_team_attending as string[],
  otherAttendees: other_attendees as string[],
  multidisciplinaryCaseMeeting: multidisciplinary_case_meeting as string,
  insulinDelivery: insulin_delivery as string,
  insulinPumpType: insulin_pump_type as string,
  pumpWarranty: pump_warranty as string,
  cannulaType: cannula_type as string,
  pumpSystemUsed: pump_system_used as string,
  bolusInsulinType: bolus_insulin_type as string,
  basalInsulinType: basal_insulin_type as string,
  totalDailyInsulinDose: total_daily_insulin_dose as number,
  totalBasalInsulinDose: total_basal_insulin_dose as number,
  totalBolusInsulinDose: total_bolus_insulin_dose as number,
  numberOfInjectionsPerDay: number_of_injections_per_day as number,
  numberOfBloodGlucoseEntriesPerDay:
    number_of_blood_glucose_entries_per_day as number,
  averageAmountOfCarbohydrateEntriesPerDay:
    average_amount_of_carbohydrate_entries_per_day as number,
  averageAmountOfCarbohydratesPerDay:
    average_amount_of_carbohydrates_per_day as number,
  lineChangeIntervalCompletedDays: line_change_interval_completed_days as number,
  cgmUsage: cgm_usage as string,
  typeOfCgm: type_of_cgm as string,
  severeHypoglycaemia: severe_hypoglycaemia as string,
  severeDka: severe_dka as string,
  variability: variability as number,
  timeInRange: time_in_range as number,
  timeBelowRange: time_below_range as number,
  timeAboveRange: time_above_range as number,
  complicationScreenDueDate: complication_screen_due_date as string,
  nephropathy: nephropathy as string[],
  thyroid: thyroid as string[],
  coeliac: coeliac as string[],
  cholesterol: cholesterol as string[],
  retinopathy: retinopathy as string[],
  otherConditions: other_conditions as string,
  bloodPressureSystole: blood_pressure_systole as number,
  bloodPressureDiastole: blood_pressure_diastole as number,
  sites: sites as string[],
  settingsAdjusted: settings_adjusted as string,
  schoolSupportAssessment: school_support_assessment as string,
  homeSupport: home_support as string,
  homeSupportOther: home_support_other as string,
  glycaemicPatterns: glycaemic_patterns as string[],
  glycaemicPatternsOther: glycaemic_patterns_other as string,
  managementAdvice: management_advice as string[],
  foodBolusSpecificStrategies: food_bolus_specific_strategies as string,
  nextConsultation: next_consultation as string,
  consultationSummary: consultation_summary as string,
  sickDayManagementPlanDue: sick_day_management_plan_due as string,
  daysInHospitalDueToDiabetesSinceLastVisit: days_in_hospital_due_to_diabetes_since_last_visit as number,
  user: {
    firstName: (user as Record<string, string>)?.first_name,
    lastName: (user as Record<string, string>)?.last_name,
    id: (user as Record<string, number>)?.id,
  },
});

const transformConsultationRequest = (
  consultation: IConsultation | Omit<IConsultation, 'id' | 'user'>
): Record<string, unknown> => ({
  id: (consultation as IConsultation).id || '',
  glycosylated_haemoglobin: consultation.hbA1c,
  glycosylated_haemoglobin_mmol: consultation.hbA1cMmol,
  consultation_date: consultation.consultationDate
    ? dayjs(consultation.consultationDate).format('YYYY-MM-DD')
    : undefined,
  telemedicine: consultation.telemedicine,
  height: consultation.height,
  weight: consultation.weight,
  medical_team_attending: consultation.medicalTeamAttending,
  other_attendees: consultation.otherAttendees,
  multidisciplinary_case_meeting: consultation.multidisciplinaryCaseMeeting,
  insulin_delivery: consultation.insulinDelivery,
  insulin_pump_type: consultation.insulinPumpType,
  pump_warranty: consultation.pumpWarranty
    ? dayjs(consultation.pumpWarranty).format('YYYY-MM-DD')
    : null,
  cannula_type: consultation.cannulaType,
  pump_system_used: consultation.pumpSystemUsed,
  bolus_insulin_type: consultation.bolusInsulinType,
  basal_insulin_type: consultation.basalInsulinType,
  total_daily_insulin_dose: consultation.totalDailyInsulinDose,
  total_basal_insulin_dose: consultation.totalBasalInsulinDose,
  total_bolus_insulin_dose: consultation.totalBolusInsulinDose,
  number_of_injections_per_day: consultation.numberOfInjectionsPerDay,
  number_of_blood_glucose_entries_per_day:
    consultation.numberOfBloodGlucoseEntriesPerDay,
  average_amount_of_carbohydrate_entries_per_day:
    consultation.averageAmountOfCarbohydrateEntriesPerDay,
  average_amount_of_carbohydrates_per_day:
    consultation.averageAmountOfCarbohydratesPerDay,
  line_change_interval_completed_days: consultation.lineChangeIntervalCompletedDays,
  cgm_usage: consultation.cgmUsage,
  type_of_cgm: consultation.typeOfCgm,
  severe_hypoglycaemia: consultation.severeHypoglycaemia,
  severe_dka: consultation.severeDka,
  variability: consultation.variability,
  time_in_range: consultation.timeInRange,
  time_below_range: consultation.timeBelowRange,
  time_above_range: consultation.timeAboveRange,
  complication_screen_due_date: consultation.complicationScreenDueDate
    ? dayjs(consultation.complicationScreenDueDate).format('YYYY-MM-DD')
    : null,
  nephropathy: consultation.nephropathy,
  thyroid: consultation.thyroid,
  coeliac: consultation.coeliac,
  cholesterol: consultation.cholesterol,
  retinopathy: consultation.retinopathy,
  other_conditions: consultation.otherConditions,
  blood_pressure_systole: consultation.bloodPressureSystole,
  blood_pressure_diastole: consultation.bloodPressureDiastole,
  sites: consultation.sites,
  settings_adjusted: consultation.settingsAdjusted,
  school_support_assessment: consultation.schoolSupportAssessment,
  home_support: consultation.homeSupport,
  home_support_other: consultation.homeSupportOther,
  glycaemic_patterns: consultation.glycaemicPatterns,
  glycaemic_patterns_other: consultation.glycaemicPatternsOther,
  management_advice: consultation.managementAdvice,
  food_bolus_specific_strategies: consultation.foodBolusSpecificStrategies,
  next_consultation: consultation.nextConsultation
    ? dayjs(consultation.nextConsultation).format('YYYY-MM-DD')
    : null,
  consultation_summary: consultation.consultationSummary,
  sick_day_management_plan_due: consultation.sickDayManagementPlanDue
    ? dayjs(consultation.sickDayManagementPlanDue).format('YYYY-MM-DD')
    : null,
  days_in_hospital_due_to_diabetes_since_last_visit: consultation.daysInHospitalDueToDiabetesSinceLastVisit,
});

export const getNewPatientConsultation = async (patientId: number): Promise<IConsultation> =>
  axiosService
    .getSecuredInstance()
    .get(`patients/${patientId}/consultations/new`)
    .then((response) => {
      const { data } = response.data as {
        data: unknown;
      };

      return transformConsultationResponse(
        data as Record<
          string,
          string | number | Record<string, string | number>
        >
      );
    });

export const getPatientConsultations = async (
  patientId: number,
  page: number,
  { order, orderBy }: SortParams
): Promise<{ consultations: IConsultation[]; total: number }> =>
  axiosService
    .getSecuredInstance()
    .get(`/patients/${patientId}/consultations`, {
      params: { page, [`q[s]`]: `${camelToSnakeCase(orderBy)} ${order}` },
    })
    .then((response) => {
      const { data, meta } = response.data as {
        data: unknown[];
        meta: {
          total_count: number;
        };
      };

      return {
        consultations: data.map((record) =>
          transformConsultationResponse(
            record as Record<
              string,
              string | number | Record<string, string | number>
            >
          )
        ),
        total: meta.total_count,
      };
    });

export const createPatientConsultation = async (
  patientId: number,
  consultationData: Omit<IConsultation, 'id' | 'user'>
): Promise<IConsultation> =>
  axiosService
    .getSecuredInstance()
    .post(
      `/patients/${patientId}/consultations`,
      transformConsultationRequest(consultationData)
    )
    .then((response) => {
      const { consultation } = response.data as {
        consultation: unknown;
      };

      return transformConsultationResponse(
        consultation as Record<
          string,
          string | number | Record<string, string | number>
        >
      );
    });

export const getPatientConsultation = async (
  patientId: number,
  consultationId: number
): Promise<IConsultation> =>
  axiosService
    .getSecuredInstance()
    .get(`/patients/${patientId}/consultations/${consultationId}`)
    .then((response) => {
      const { data } = response.data as {
        data: unknown;
      };

      return transformConsultationResponse(
        data as Record<
          string,
          string | number | Record<string, string | number>
        >
      );
    });

export const updatePatientConsultation = async (
  patientId: number,
  consultation: Omit<IConsultation, 'user'>
): Promise<IConsultation> =>
  axiosService
    .getSecuredInstance()
    .put(
      `/patients/${patientId}/consultations/${consultation.id}`,
      transformConsultationRequest(consultation)
    )
    .then((response) => {
      const data = response.data as {
        consultation: unknown;
      };

      return transformConsultationResponse(
        data as Record<
          string,
          string | number | Record<string, string | number>
        >
      );
    });

export const consultationService: IConsultationService = Object.freeze({
  getNewPatientConsultation,
  getPatientConsultations,
  getPatientConsultation,
  createPatientConsultation,
  updatePatientConsultation,
});

import {
  action,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
} from 'mobx';
import { IInsulinDosage, INewInsulinDosageData } from 'src/types/InsulinDosage';
import { IInsulinDosageService } from 'src/types/InsulinDosageService';
import { AuthStore } from './AuthStore';
import { DefaultStore } from './DefaultStore';
import { serviceLoader } from './ServiceLoader';

export interface IInsulinDosageStoreState {
  patientInsulinDosages: ObservableMap<number, IInsulinDosage>;
}

export class InsulinDosageStore extends DefaultStore {
  state: IInsulinDosageStoreState = {
    patientInsulinDosages: new ObservableMap(),
  };

  insulinDosageService: IInsulinDosageService;

  constructor(authStore: AuthStore) {
    super(authStore);

    makeObservable(this, {
      state: observable,
      getPatientInsulinDosage: action.bound,
    });

    this.insulinDosageService = serviceLoader.load('insulinDosageService');
  }

  async getPatientInsulinDosage(patientId: number): Promise<void> {
    const insulinDosage = await this.insulinDosageService.getInsulinDosage(
      patientId
    );

    runInAction(() => {
      if (insulinDosage) {
        this.state.patientInsulinDosages.set(patientId, insulinDosage);
      }
    });
  }

  async createPatientInsulinDosage(
    patientId: number,
    insulinDosageData: INewInsulinDosageData
  ): Promise<IInsulinDosage> {
    const insulinDosage = await this.insulinDosageService.create(
      patientId,
      insulinDosageData
    );

    runInAction(() => {
      if (insulinDosage) {
        this.state.patientInsulinDosages.set(patientId, insulinDosage);
      }
    });

    return insulinDosage;
  }

  clear(): void {
    this.state.patientInsulinDosages.clear();
  }
}

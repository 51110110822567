import { SxProps } from '@mui/system';

export const formSectionStyles: SxProps = {
  paddingBottom: 7,
};

export const fieldsRowStyles: SxProps = {
  marginBottom: 4,
};

export const sectionTitleStyles: SxProps = {
  marginBottom: 2,
};

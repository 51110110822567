import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { DefaultLink } from 'src/components/DefaultLink';
import { breadcrumbsStyles, breadcrumbsSeparatorStyles } from './styles';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

type BreadcrumbsLink = {
  url?: string;
  label: string;
};

type BreadcrumbsProp = {
  links: BreadcrumbsLink[];
};

export function Breadcrumbs({ links }: BreadcrumbsProp): JSX.Element {
  return (
    <Typography color="charcoal.main" sx={breadcrumbsStyles} variant="h4">
      {links.map(({ url, label }, index) => (
        <Fragment key={url || label}>
          {url ? (
            <DefaultLink to={url}>{label}</DefaultLink>
          ) : (
            <span>{label}</span>
          )}
          {index === links.length - 1 ? null : (
            <KeyboardArrowRight sx={breadcrumbsSeparatorStyles} />
          )}
        </Fragment>
      ))}
    </Typography>
  );
}

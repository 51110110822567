import { Box, CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DefaultLink } from 'src/components/DefaultLink';
import { AuthLayout } from 'src/components/layouts/AuthLayout';
import { useHandleReconfirmAccount } from 'src/hooks/ReconfirmAccountHook';
import { getSearchParams } from 'src/utils/getSearchParams';
import { errorStyles, passwordGuideStyles } from './styles';

const ReconfirmAccountPage = observer(function (): JSX.Element {
  const confirmationToken = getSearchParams('confirmation_token');

  const { isLoading, error, isSuccessful } =
    useHandleReconfirmAccount(confirmationToken);

  return (
    <AuthLayout title="Reconfirm Account">
      {!!confirmationToken ? (
        <>
          {!!error && (
            <Typography sx={errorStyles} color="error.main">
              {error}
            </Typography>
          )}
          {isSuccessful && (
            <Box>
              <Typography sx={passwordGuideStyles} color="charcoal.main">
                Your account has been successfully confirmed. Please click{' '}
                <DefaultLink to="/">here to login.</DefaultLink>
              </Typography>
            </Box>
          )}
          {isLoading && <CircularProgress />}
        </>
      ) : (
        <Typography color="error.main">No confirmation token found</Typography>
      )}
    </AuthLayout>
  );
});

export default ReconfirmAccountPage;

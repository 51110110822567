import { TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { useMemo } from 'react';
import { DefaultTextField } from './DefaultTextField';

type FormikTextFieldProps = TextFieldProps & {
  name: string;
};

export function FormikTextField({
  name,
  variant = 'outlined',
  multiline,
  ...rest
}: FormikTextFieldProps): JSX.Element {
  const [field, meta] = useField(name);
  const { touched, error } = meta;
  const shouldShowError = !!touched && !!error;

  const renderedField = useMemo(
    () => (
      <DefaultTextField
        {...field}
        {...rest}
        value={field.value || ''}
        name={name}
        error={shouldShowError}
        helperText={shouldShowError ? error : ''}
        variant={variant}
        multiline={multiline}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shouldShowError,
      error,
      variant,
      name,
      multiline,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(rest),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(field),
    ]
  );

  return renderedField;
}

import { SxProps } from '@mui/system';

export const inputStyles: SxProps = {
  paddingTop: '12.5px',
  paddingBottom: '12.5px',
  width: '100%',
};

export const inputWrapperStyles: SxProps = {
  width: '100%',
  '& fieldset': {
    borderColor: '#9F9F9F',
  },
};

export const labelStyles: SxProps = {
  transform: 'translate(14px, 12.5px) scale(1)',
  '&.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
};

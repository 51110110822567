import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { useResponsiveImage } from './hook';

type ResponsiveImageProp = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  loadingText?: React.ReactNode;
  imageSrc: string;
};

export function ResponsiveImage({
  loadingText = 'Loading',
  imageSrc,
  alt = '',
  ...imageProps
}: ResponsiveImageProp): JSX.Element {
  const [srcSet, { isLoading }] = useResponsiveImage(imageSrc);

  return (
    <>
      {!!isLoading && <span>{loadingText}</span>}
      {!!srcSet ? <img srcSet={srcSet} alt={alt} {...imageProps} /> : undefined}
    </>
  );
}

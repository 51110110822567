import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { useAuth } from 'src/hooks/AuthHook';

export const PrivateRoute = observer(function ({
  component: Component,
  render,
  ...rest
}: RouteProps): JSX.Element {
  const { user: { email } = {} } = useAuth();

  const renderOrRedirect = useCallback(
    (props: RouteComponentProps) => {
      if (!email || (!Component && !render)) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }

      if (Component) {
        return <Component {...props} />;
      }

      if (render) {
        return render(props);
      }
    },
    [email, Component, render]
  );

  return <Route {...rest} render={renderOrRedirect} />;
});

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent } from 'react';
import {
  InjectionSettingInputData,
  InjectionSettingItem,
} from './InjectionSettingItem';
import { injectionSettingHeaderStyles, timeColumnStyles } from './styles';

type InjectionSettingsInputProp = {
  value?: Record<string, InjectionSettingInputData>;
  onChange: (
    event: {
      target: {
        name: string;
        value: Record<string, InjectionSettingInputData>;
      };
    } & ChangeEvent
  ) => void;
  name: string;
  error?: string;
};

const timeSlots = [
  { from: '00:00', to: '06:00' },
  { from: '06:00', to: '10:00' },
  { from: '10:00', to: '16:00' },
  { from: '16:00', to: '00:00' },
];

export function InjectionSettingsInput({
  value,
  onChange,
  name,
  error,
}: InjectionSettingsInputProp): JSX.Element {
  const handleSlotChange = (setting: InjectionSettingInputData) => {
    onChange({
      target: { name, value: { ...value, [setting.timeFrom]: setting } },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
  };

  return (
    <Box>
      {error && <Typography color="error.main">{error}</Typography>}
      <Grid sx={injectionSettingHeaderStyles} spacing={3} container>
        <Grid item sx={timeColumnStyles}>
          Time
        </Grid>
        <Grid item xs>
          I/C Ratio
        </Grid>
        <Grid item xs>
          Correction Factor
        </Grid>
        <Grid item xs>
          Basal Insulin
        </Grid>
      </Grid>
      {timeSlots.map(({ from, to }) => (
        <InjectionSettingItem
          value={value && value[from]}
          from={from}
          to={to}
          key={from}
          onChange={handleSlotChange}
        />
      ))}
    </Box>
  );
}

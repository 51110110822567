import { IReconfirmAccountService } from 'src/types/ReconfirmAccountService';
import { axiosService } from './AxiosService';

export const reconfirmAccount = async (token: string): Promise<void> =>
  axiosService.getPublicInstance().get('/confirmation', {
    params: { confirmation_token: token },
  });

export const reconfirmAccountService: IReconfirmAccountService = Object.freeze({
  reconfirmAccount,
});

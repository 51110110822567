import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useAuth } from 'src/hooks/AuthHook';
import { AcceptInvitationPage } from 'src/pages/AcceptInvitationPage';
import { ForgotPasswordPage } from 'src/pages/ForgotPasswordPage';
import { LoginPage } from 'src/pages/LoginPage';
import ReconfirmAccountPage from 'src/pages/ReconfirmAccountPage/ReconfirmAccountPage';
import { ResetPasswordPage } from 'src/pages/ResetPasswordPage';

export const RoutesManagerPublic = observer(function (): JSX.Element {
  const { user: { email } = {} } = useAuth();
  const history = useHistory();

  // Redirect if user logged in
  useEffect(() => {
    let inPage = true;

    if (email && inPage) {
      history.replace('/dashboard');
    }

    return () => {
      inPage = false;
    };
  }, [email, history]);

  return (
    <Switch>
      <Route exact path="/forgot-password" component={ForgotPasswordPage} />
      <Route exact path="/auth/invitation" component={AcceptInvitationPage} />
      <Route exact path="/auth/confirmation" component={ReconfirmAccountPage} />
      <Route exact path="/auth/reset-password" component={ResetPasswordPage} />
      <Route exact component={LoginPage} />
    </Switch>
  );
});

import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSinglePatient } from 'src/hooks/PatientHook';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import {
  ConsultationForm,
  ConsultationFormData,
} from 'src/components/forms/ConsultationForm';
import { PageHeading } from 'src/components/PageHeading';
import {
  useCreatePatientConsultation,
  useFetchNewConsultation,
} from 'src/hooks/ConsultationHook';
import { IConsultation } from 'src/types/Consultation';

export function NewConsultationPage(): JSX.Element {
  const { patientId } = useParams<{ patientId: string }>();
  const [patient] = useSinglePatient({ patientId, fetchImmediately: true });
  const fullName = `${patient?.firstName} ${patient?.lastName}`;
  const [newConsultation] = useFetchNewConsultation({ fetchImmediately: true, patientId });

  const [createConsultation, updateState] = useCreatePatientConsultation();
  const history = useHistory();

  const handleCreatePatientConsultation = useCallback(
    async (consultationData: ConsultationFormData): Promise<void> => {
      const parsedPatientId = parseInt(patientId, 10);
      await createConsultation(
        parsedPatientId,
        consultationData as Omit<IConsultation, 'id' | 'user'>
      );

      history.push(`/dashboard/patients/${patientId}/consultation`);
    },
    [history, patientId, createConsultation]
  );

  const prefilledConsultation: Partial<ConsultationFormData> = useMemo(
    () => ({
      hbA1c: newConsultation?.hbA1c,
      hbA1cMmol: newConsultation?.hbA1cMmol,
      telemedicine: newConsultation?.telemedicine,
      height: newConsultation?.height,
      weight: newConsultation?.weight,
      medicalTeamAttending: newConsultation?.medicalTeamAttending || [],
      otherAttendees: newConsultation?.otherAttendees || [],
      multidisciplinaryCaseMeeting: newConsultation?.multidisciplinaryCaseMeeting,
      insulinDelivery: newConsultation?.insulinDelivery,
      insulinPumpType: newConsultation?.insulinPumpType,
      pumpWarranty: newConsultation?.pumpWarranty,
      cannulaType: newConsultation?.cannulaType,
      pumpSystemUsed: newConsultation?.pumpSystemUsed,
      bolusInsulinType: newConsultation?.bolusInsulinType,
      basalInsulinType: newConsultation?.basalInsulinType,
      totalDailyInsulinDose: newConsultation?.totalDailyInsulinDose,
      totalBasalInsulinDose: newConsultation?.totalBasalInsulinDose,
      totalBolusInsulinDose: newConsultation?.totalBolusInsulinDose,
      numberOfInjectionsPerDay: newConsultation?.numberOfInjectionsPerDay,
      numberOfBloodGlucoseEntriesPerDay: newConsultation?.numberOfBloodGlucoseEntriesPerDay,
      averageAmountOfCarbohydrateEntriesPerDay: newConsultation?.averageAmountOfCarbohydrateEntriesPerDay,
      averageAmountOfCarbohydratesPerDay: newConsultation?.averageAmountOfCarbohydratesPerDay,
      lineChangeIntervalCompletedDays: newConsultation?.lineChangeIntervalCompletedDays,
      cgmUsage: newConsultation?.cgmUsage,
      typeOfCgm: newConsultation?.typeOfCgm,
      severeHypoglycaemia: newConsultation?.severeHypoglycaemia,
      severeDka: newConsultation?.severeDka,
      variability: newConsultation?.variability,
      timeInRange: newConsultation?.timeInRange,
      timeBelowRange: newConsultation?.timeBelowRange,
      timeAboveRange: newConsultation?.timeAboveRange,
      complicationScreenDueDate: newConsultation?.complicationScreenDueDate,
      nephropathy: newConsultation?.nephropathy || [],
      thyroid: newConsultation?.thyroid || [],
      coeliac: newConsultation?.coeliac || [],
      cholesterol: newConsultation?.cholesterol || [],
      retinopathy: newConsultation?.retinopathy || [],
      otherConditions: newConsultation?.otherConditions,
      bloodPressureSystole: newConsultation?.bloodPressureSystole,
      bloodPressureDiastole: newConsultation?.bloodPressureDiastole,
      sites: newConsultation?.sites || [],
      settingsAdjusted: newConsultation?.settingsAdjusted,
      schoolSupportAssessment: newConsultation?.schoolSupportAssessment,
      homeSupport: newConsultation?.homeSupport,
      glycaemicPatterns: newConsultation?.glycaemicPatterns || [],
      managementAdvice: newConsultation?.managementAdvice || [],
      homeSupportOther: newConsultation?.homeSupportOther,
      glycaemicPatternsOther: newConsultation?.glycaemicPatternsOther,
      foodBolusSpecificStrategies: newConsultation?.foodBolusSpecificStrategies,
      nextConsultation: newConsultation?.nextConsultation,
      consultationSummary: newConsultation?.consultationSummary,
      daysInHospitalDueToDiabetesSinceLastVisit: newConsultation?.daysInHospitalDueToDiabetesSinceLastVisit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newConsultation]
  );

  return (
    <>
      <PageHeading
        title="Consultation"
        subheading={
          <Breadcrumbs
            links={[
              { label: 'Patients', url: '/dashboard' },
              { label: fullName, url: `/dashboard/patients/${patientId}` },
              { label: 'New consultation' },
            ]}
          />
        }
      />
      <ConsultationForm
        defaultValues={prefilledConsultation}
        onSubmit={handleCreatePatientConsultation}
        isLoading={updateState.isLoading}
        errors={updateState.errors}
      />
    </>
  );
}

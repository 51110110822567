import { SxProps } from '@mui/system';

export const defaultLinkStyles: SxProps = {
  '& a': {
    color: 'primary.main',
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

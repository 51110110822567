import { IConsultation } from 'src/types/Consultation';
import {
  DefaultTable,
  DefaultTableProp,
} from 'src/components/tables/DefaultTable';
import { ConsultationTableRow } from './ConsultationTableRow';

type ConsultationTableProp = Omit<
  DefaultTableProp,
  'headCells' | 'children'
> & {
  consultations: IConsultation[];
  onRowClick: (patient: IConsultation) => void;
};

const headCells = [
  { cellId: 'consultationDate', label: 'Date Visited' },
  { cellId: 'user.firstName', label: 'Clinician' },
  { cellId: 'glycosylatedHaemoglobin', label: 'HbA1c (%)' },
];

export function ConsultationTable({
  consultations,
  onRowClick,
  ...tableProps
}: ConsultationTableProp): JSX.Element {
  return (
    <DefaultTable headCells={headCells} {...tableProps}>
      {consultations.map((consultation) => (
        <ConsultationTableRow
          onRowClick={onRowClick}
          key={consultation.id}
          consultation={consultation}
        />
      ))}
    </DefaultTable>
  );
}

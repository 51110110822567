import { Box, CircularProgress } from '@mui/material';
import { loadingContainerStyles } from './styles';

export function LoadingScreen(): JSX.Element {
  return (
    <Box sx={loadingContainerStyles}>
      <CircularProgress />
    </Box>
  );
}

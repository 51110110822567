import { Box, MenuItem, MenuItemProps } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  dashboardLayoutHeaderMenuItemStyles,
  menuContentWrapperStyles,
  menuContentIconStyles,
} from './styles';

type DashboardLayoutHeaderMenuItemProps = MenuItemProps & {
  icon: ReactNode;
  content: ReactNode;
  url?: string;
};

export function DashboardLayoutHeaderMenuItem({
  icon,
  content,
  url,
  ...props
}: DashboardLayoutHeaderMenuItemProps): JSX.Element {
  const menuContent = (
    <>
      <Box sx={menuContentIconStyles}>{icon}</Box> {content}
    </>
  );

  return (
    <MenuItem sx={dashboardLayoutHeaderMenuItemStyles} {...props}>
      {url ? (
        <Link to={url}>{menuContent}</Link>
      ) : (
        <Box sx={menuContentWrapperStyles}>{menuContent}</Box>
      )}
    </MenuItem>
  );
}

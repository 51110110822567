import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ResponsiveImage } from 'src/components/ResponsiveImage';
import { ReactComponent as Logo } from 'src/assets/svgs/logo.svg';
import {
  publicImageStyles,
  mainContentStyles,
  mainHeaderStyles,
  mainContentContainerStyles,
  rightColStyles,
  mainContentLogoStyles,
  subtitleStyles,
  mainContentBoxStyles,
} from './styles';

type AuthLayoutProp = {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
};

export function AuthLayout({
  title,
  subtitle,
  children,
}: AuthLayoutProp): JSX.Element {
  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Box sx={publicImageStyles}>
            <ResponsiveImage alt="Diabeasy" imageSrc="login.png" />
          </Box>
        </Grid>
        <Grid sx={rightColStyles} item xs={6}>
          <Box sx={mainContentContainerStyles}>
            <Box sx={mainContentStyles}>
              <Box sx={mainContentLogoStyles}>
                <Logo title="logo" />
              </Box>
              <Box sx={mainHeaderStyles}>
                <Typography variant="h3">{title}</Typography>
                <Typography sx={subtitleStyles}>{subtitle}</Typography>
              </Box>
              <Box sx={mainContentBoxStyles}>{children}</Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import { SxProps } from '@mui/system';

export const breadcrumbsStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: 1.5,
  paddingBottom: 2,
};

export const breadcrumbsSeparatorStyles: SxProps = {
  paddingLeft: 0.5,
  paddingRight: 0.5,
  boxSizing: 'content-box',
};

import { observer } from 'mobx-react-lite';
import { Switch, useRouteMatch } from 'react-router-dom';
import { DashboardLayout } from 'src/components/layouts/DashboardLayout';
import { DashboardPage } from 'src/pages/DashboardPage';
import { PrivateRoute } from 'src/components/PrivateRoute';
import { SinglePatientPage } from 'src/pages/SinglePatientPage';
import { NewPatientPage } from 'src/pages/NewPatientPage';
import { NewConsultationPage } from 'src/pages/NewConsultationPage';
import { SingleConsultationPage } from 'src/pages/SingleConsultationPage';
import { AccountPage } from 'src/pages/AccountPage';

export const RoutesManagerDashboard = observer(function (): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <DashboardLayout>
      <Switch>
        <PrivateRoute path={`${path}/account`} component={AccountPage} />
        <PrivateRoute path={`${path}/new-patient`} component={NewPatientPage} />
        <PrivateRoute
          path={`${path}/patients/:patientId/new-consultation`}
          exact
          component={NewConsultationPage}
        />
        <PrivateRoute
          path={`${path}/patients/:patientId/consultation/:consultationId`}
          exact
          component={SingleConsultationPage}
        />
        <PrivateRoute
          path={`${path}/patients/:patientId`}
          component={SinglePatientPage}
        />
        <PrivateRoute path={path} exact component={DashboardPage} />
      </Switch>
    </DashboardLayout>
  );
});

import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { transformConsultationResponse } from 'src/services/ConsultationService';
import { useStores } from 'src/stores';
import { IConsultation } from 'src/types/Consultation';
import { FetchDataHook, HandlerHook } from 'src/types/Hook';
import { SortParams } from 'src/types/SortParams';
import { useAsyncState } from './AsyncStateHook';

export const useFetchNewConsultation: FetchDataHook<
  IConsultation | undefined,
  { patientId: string },
  () => Promise<void>
> = (params) => {
  const { fetchImmediately = true, patientId } = params || {};
  const { state, start, finish } = useAsyncState();
  const { consultationStore } = useStores();
  const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;

  const consultation = consultationStore.state.newConsultation;

  const refetch = useCallback(async () => {
    start();
    try {
      await consultationStore.getNewPatientConsultation(parsedPatientId);

      finish();
    } catch (error) {
      finish({
        error: (error as Error)?.message || 'Could not get new consultation',
      });
    }
  }, [consultationStore, parsedPatientId, finish, start]);

  useEffect(() => {
    if (!fetchImmediately) {
      return;
    }

    refetch();
  }, [fetchImmediately, refetch]);

  return [consultation, { ...state, refetch }];
};

export const useFetchConsultations: FetchDataHook<
  { consultations: IConsultation[]; total: number },
  { initialSort: SortParams; patientId: string },
  (patientId: number, page: number, sort: SortParams) => Promise<void>
> = (params) => {
  const {
    fetchImmediately = true,
    initialSort: { order = '', orderBy = '' } = {},
    patientId,
  } = params || {};
  const [consultations, setConsultations] = useState<IConsultation[]>([]);
  const [total, setTotal] = useState(0);
  const { state, start, finish } = useAsyncState();
  const { consultationStore } = useStores();
  const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;

  const refetch = useCallback(
    async (patientId: number, page: number, sort) => {
      start();
      try {
        const { consultations = [], total = 0 } =
          (await consultationStore.getPatientConsultations(
            patientId,
            page,
            sort
          )) || {};

        setConsultations(consultations);
        setTotal(total);
        finish();
      } catch (error) {
        finish({
          error: (error as Error)?.message || 'Could not get patient data',
        });
      }
    },
    [consultationStore, finish, start]
  );

  useEffect(() => {
    if (!fetchImmediately) {
      return;
    }

    refetch(parsedPatientId, 1, order && orderBy && { order, orderBy });
  }, [fetchImmediately, refetch, order, orderBy, parsedPatientId]);

  return [
    { consultations, total },
    { ...state, refetch },
  ];
};

export const useFetchConsultation: FetchDataHook<
  IConsultation | undefined,
  { consultationId: string; patientId: string },
  () => Promise<void>
> = (params) => {
  const { fetchImmediately = true, patientId, consultationId } = params || {};
  const { state, start, finish } = useAsyncState();
  const { consultationStore } = useStores();
  const parsedPatientId = patientId ? parseInt(patientId, 10) : 0;
  const parsedConsultationId = consultationId
    ? parseInt(consultationId, 10)
    : 0;

  const consultation = consultationStore.findPatientConsultationById(
    parsedPatientId,
    parsedConsultationId
  );

  const refetch = useCallback(async () => {
    start();
    try {
      await consultationStore.getPatientConsultation(
        parsedPatientId,
        parsedConsultationId
      );

      finish();
    } catch (error) {
      finish({
        error: (error as Error)?.message || 'Could not get patient data',
      });
    }
  }, [consultationStore, parsedPatientId, parsedConsultationId, finish, start]);

  useEffect(() => {
    if (!fetchImmediately) {
      return;
    }

    refetch();
  }, [fetchImmediately, refetch]);

  return [consultation, { ...state, refetch }];
};

export const useCreatePatientConsultation: HandlerHook<
  (
    patientId: number,
    consultationData: Omit<IConsultation, 'id' | 'user'>
  ) => Promise<IConsultation | undefined>
> = () => {
  const { state, start, finish } = useAsyncState();
  const { consultationStore, alertStore } = useStores();

  const handleSubmit = useCallback(
    async (
      patientId: number,
      consultationData: Omit<IConsultation, 'id' | 'user'>
    ) => {
      try {
        start();
        const consultation = await consultationStore.createConsultation(
          patientId,
          consultationData
        );

        alertStore.addAlert({
          content: `Consultation at ${dayjs(
            consultation.consultationDate
          ).format('DD/MM/YYYY')} has been created successfully`,
          type: 'success',
        });

        finish();

        return consultation;
      } catch (error) {
        const errorMessage = 'Could not create consultation';

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        const axiosErrorMessages = checkErrorForDataErrors(error as Error);

        finish({
          error: errorMessage,
          errors: axiosErrorMessages,
        });
      }
    },
    [start, finish, consultationStore, alertStore]
  );

  return [handleSubmit, { ...state }];
};

export const useUpdatePatientConsultation: HandlerHook<
  (
    patientId: number,
    consultationData: Omit<IConsultation, 'user'>
  ) => Promise<IConsultation | undefined>
> = () => {
  const { state, start, finish } = useAsyncState();
  const { consultationStore, alertStore } = useStores();

  const handleSubmit = useCallback(
    async (
      patientId: number,
      consultationData: Omit<IConsultation, 'user'>
    ) => {
      try {
        start();
        const consultation = await consultationStore.updateConsultation(
          patientId,
          consultationData
        );

        alertStore.addAlert({
          content: `Consultation at ${dayjs(
            consultation.consultationDate
          ).format('DD/MM/YYYY')} has been updated successfully`,
          type: 'success',
        });

        finish();

        return consultation;
      } catch (error) {
        const errorMessage = 'Could not update consultation';

        alertStore.addAlert({ content: errorMessage, type: 'error' });

        const axiosErrorMessages = checkErrorForDataErrors(error as Error);

        finish({
          error: errorMessage,
          errors: axiosErrorMessages,
        });
      }
    },
    [start, finish, consultationStore, alertStore]
  );

  return [handleSubmit, { ...state }];
};

const checkErrorForDataErrors = (error: Error): Record<string, string> | undefined => {
  let axiosErrorMessagesTransformed: Record<string, string> | undefined;
  if (axios.isAxiosError(error) && error.response) {
    const axiosErrorMessages =
      (error as AxiosError<{ errors: Record<string, string[]> }>).response?.data?.errors;

    const joinedErrors: Record<string, string> = {};
    Object.keys(axiosErrorMessages || {}).forEach((key) => {
      const values = (axiosErrorMessages || {})[key];
      joinedErrors[key] = values.join(', ');
    });

    axiosErrorMessagesTransformed = transformConsultationResponse(joinedErrors) as Partial<IConsultation> as Record<string, string>;
  }

  return axiosErrorMessagesTransformed;
};

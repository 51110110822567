import { Dialog, DialogProps, DialogTitle } from '@mui/material';
import { dialogStyles, dialogTitleStyles } from './styles';

type DefaultDialogProp = DialogProps & {
  title: string;
};

export function DefaultDialog({
  title,
  children,
  ...dialogProps
}: DefaultDialogProp): JSX.Element {
  return (
    <Dialog PaperProps={{ sx: dialogStyles }} {...dialogProps}>
      <DialogTitle sx={dialogTitleStyles}>{title}</DialogTitle>
      {children}
    </Dialog>
  );
}

import { observer } from 'mobx-react-lite';
import { SinglePatientForm } from 'src/components/forms/SinglePatientForm';
import { useDialogState } from 'src/hooks/DialogStateHook';
import { useUpdatePatient } from 'src/hooks/PatientHook';
import { IPatient } from 'src/types/Patient';
import { SinglePatientDeleteDialog } from './SinglePatientDeleteDialog';

export type SinglePatientPageDetailsProps = {
  patient: IPatient;
};

export const SinglePatientPageDetails = observer(function ({
  patient,
}: SinglePatientPageDetailsProps): JSX.Element {
  const [updatePatient, updateState] = useUpdatePatient();
  const [
    { isOpened: isDeleteDialogOpened },
    handleRequestDelete,
    handleCloseDeleteDialog,
  ] = useDialogState();

  const handleUpdatePatient = (patientData: Omit<IPatient, 'id'>): void => {
    updatePatient({ id: patient.id, ...patientData });
  };

  return (
    <>
      <SinglePatientForm
        defaultValues={patient}
        onSubmit={handleUpdatePatient}
        onDelete={handleRequestDelete}
        isLoading={updateState.isLoading}
        errors={updateState.errors}
      />
      <SinglePatientDeleteDialog
        patient={patient}
        isOpened={isDeleteDialogOpened}
        onClose={handleCloseDeleteDialog}
      />
    </>
  );
});

import { AuthenticationData, IAuthService } from 'src/types/AuthService';
import Cookies from 'js-cookie';
import { axiosService } from './AxiosService';
import { IUser } from 'src/types/User';
import { AxiosError } from 'axios';

const AUTHENTICATION_USER = 'AUTHENTICATION_USER';
const AUTHENTICATION_TOKEN = 'AUTHENTICATION_TOKEN';

const transformUserResponse = ({
  id,
  first_name,
  last_name,
  clinic_id,
  email,
}: Record<string, unknown>): IUser => ({
  id: parseInt(id as string, 10),
  firstName: first_name as string,
  lastName: last_name as string,
  clinicId: parseInt(clinic_id as string, 10),
  email: email as string,
});

export const login = async (
  email: string,
  password: string
): Promise<{ user: IUser; authenticationToken: string }> => {
  try {
    const { headers, data } = await axiosService
      .getPublicInstance()
      .post('/login', {
        user: {
          email,
          password,
        },
      });

    if (
      !headers.authorization ||
      headers.authorization.split(' ').length <= 1
    ) {
      throw new Error('Could not login');
    }

    const authorizationParts = headers.authorization.split(' ');
    const authenticationToken = authorizationParts[1];
    const transformedUser = transformUserResponse(
      data as Record<string, unknown>
    );

    saveAuthenticationData(transformedUser, authenticationToken);

    return {
      authenticationToken,
      user: transformedUser,
    };
  } catch (error) {
    // If it's a server error such as 401 or 422, try to get the message
    if ((error as AxiosError)?.response?.data) {
      const responseData = (error as AxiosError)?.response?.data;

      throw new Error((responseData as Record<string, string>).error);
    }

    throw error;
  }
};

export const logout = async (): Promise<void> => {
  Cookies.remove(AUTHENTICATION_TOKEN);
  Cookies.remove(AUTHENTICATION_USER);

  return Promise.resolve();
};

const parseUserFromCookies = ({
  id,
  clinicId,
  firstName,
  lastName,
  email,
}: Record<string, string>): IUser => {
  if (!id) {
    throw Error('Unable to read user from cookies');
  }

  return {
    id: parseInt(id, 10),
    clinicId: parseInt(clinicId, 10),
    firstName,
    lastName,
    email,
  };
};

export const getAuthenticationData = (): AuthenticationData => {
  const authenticationToken = Cookies.get(AUTHENTICATION_TOKEN);
  const cookiesUser = Cookies.get(AUTHENTICATION_USER);

  if (!authenticationToken) {
    throw new Error('Authentication token not found');
  }

  if (!cookiesUser) {
    throw new Error('User not found');
  }

  const user = parseUserFromCookies(JSON.parse(cookiesUser));

  return { authenticationToken, user };
};

/**
 * Save authentication data to cookies
 */
export const saveAuthenticationData = async (
  user: IUser,
  authenticationToken: string
): Promise<void> => {
  Cookies.set(AUTHENTICATION_USER, JSON.stringify(user));
  Cookies.set(AUTHENTICATION_TOKEN, authenticationToken);
};

export const authService: IAuthService = Object.freeze({
  login,
  logout,
  getAuthenticationData,
});
